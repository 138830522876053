import * as React from 'react';
import { Component } from 'react';
import { ExpansiblePanelProps, TextEditablePanelProps, DeletablePanelProps } from './SentencePanel';
import SentencePanel from './SentencePanel';
import { Dialog, DialogContent, DialogContentText, TextField, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { RecipeGenerationTypes } from 'recipe-generation';

interface EquipmentPanelProps extends ExpansiblePanelProps, TextEditablePanelProps, DeletablePanelProps {
    allEquipment:RecipeGenerationTypes.RawRecipeEquipment[];
    onMoveEquipmentBefore:(id:string)=>void;
}

class EquipmentPanel extends Component<EquipmentPanelProps> {

    public state = {
        openDialogKey:"",
        moveBefore:undefined
    };

    constructor(props: EquipmentPanelProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveMove = this.handleSaveMove.bind(this);
    }

    public render() {
        const { text, ...props } = this.props;
        return (
            <React.Fragment>
                <SentencePanel
                    title="Equipment"
                    summary={text}
                    text={text}
                    tagTypes={[]}
                    onTag={(type, span) => console.log("type" + type + JSON.stringify(span))}
                    customButtons={["Move"]}
                    onCustomButton={
                        (button) => {
                            this.handleOpenDialog("move-equipment");
                        }
                    }
                    {...props} >
                </SentencePanel>
                {this.props.expanded && <Dialog
                    fullWidth
                    open={this.state.openDialogKey === "move-equipment"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Move Equipment</DialogTitle>
                    <DialogContent>
                        <DialogContentText key="help">Move the equipment before another equipment</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"category"}
                            select
                            key={"category-textfield"}
                            value={this.state.moveBefore !== undefined ? this.state.moveBefore : -1}
                            onChange={(element) => {
                                let value = parseInt(element.target.value);
                                if (value === -1) {
                                    this.setState({ moveBefore: undefined });
                                } else {
                                    this.setState({ moveBefore: value });
                                }
                            }}
                            SelectProps={{
                                native: true
                            }}>
                            <option key="null" value={-1}>None</option>
                            {this.props.allEquipment.map((value, index) => (
                                <option key={value.id} value={index}>
                                    {value.text}
                                </option>
                            ))}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.handleSaveMove() }} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>}
            </React.Fragment>
        )
    }

    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.setState({ openDialogKey: "", moveBefore:undefined });
    }

    private handleSaveMove() {
        let index = this.state.moveBefore;
        if(index !== undefined){
            let equipment = this.props.allEquipment[index];
            this.props.onMoveEquipmentBefore(equipment.id);
        }
        this.setState({ openDialogKey: "", moveBefore:undefined });
    }

}

export default EquipmentPanel;