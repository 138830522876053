import { LOAD_IMPORTS_SUCCESS, LOAD_IMPORTS_START, LOAD_IMPORTS_FAILED, LOAD_IMPORT_START, LOAD_IMPORT_SUCCESS, LOAD_IMPORT_FAILED, CREATE_IMPORT_START, CREATE_IMPORT_FAILED, CREATE_IMPORT_SUCCESS, PUBLISH_IMPORT_START, SAVE_IMPORT_START, UPLOAD_IMAGE_START, UPLOAD_IMAGE_FAILED, UPLOAD_IMAGE_SUCCESS, ImageUploadPath, SAVE_IMPORT_SUCCESS } from "./types";
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { Action } from 'redux';
import { APIClient } from '../../apiclient';
import { RecipeGenerationTypes } from 'recipe-generation';

export const loadImports = () : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: LOAD_IMPORTS_START});
    client.getImports().then( result => {
        dispatch({
            type: LOAD_IMPORTS_SUCCESS,
            payload: result
          });
    }).catch( err => {
        dispatch({
            type: LOAD_IMPORTS_FAILED,
            error: err
          });
    });
}

export const loadImport = (id:string) : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: LOAD_IMPORT_START});
    client.getImport(id).then( result => {
        dispatch({
            type: LOAD_IMPORT_SUCCESS,
            payload: result
          });
    }).catch( err => {
        dispatch({
            type: LOAD_IMPORT_FAILED,
            error: err
          });
    });
}

export const createImport = (url:string) : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: CREATE_IMPORT_START});
    client.createImport(url).then( result => {
        dispatch({
            type: CREATE_IMPORT_SUCCESS,
            payload: result
          });
    }).catch( err => {
        dispatch({
            type: CREATE_IMPORT_FAILED,
            error: err
          });
    });
}

export const uploadImage = (path:ImageUploadPath, file:File) : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: UPLOAD_IMAGE_START, path:path});
    client.uploadImage(path, file).then( result => {
        dispatch({
            type: UPLOAD_IMAGE_SUCCESS,
            path: path,
            href: result
          });
    }).catch( err => {
        console.log(err);
        dispatch({
            type: UPLOAD_IMAGE_FAILED,
            path:path,
            error: err
          });
    });
}

export const publishImport = (id:string) : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: PUBLISH_IMPORT_START});
    try {
        await client.publishImport(id);
    } catch (err) {
        console.log(err);
    }
}

export const saveImport = (id:string,
    original?:RecipeGenerationTypes.RawRecipe,
    tags?:RecipeGenerationTypes.RecipeTags) : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: SAVE_IMPORT_START, original:original, tags:tags});
    try {
        await client.save(id, original, tags);
        dispatch({type: SAVE_IMPORT_SUCCESS});
    } catch (err) {
        console.log(err);
    }
}