import * as React from 'react';
import { Component } from 'react';
import {Span, ExpansiblePanelProps, TextEditablePanelProps} from './SentencePanel';
import SentencePanel from './SentencePanel';
import SentencePanelItem from './items/SentencePanelItem';

interface DescriptionPanelProps extends ExpansiblePanelProps, TextEditablePanelProps {
    introSpan?: Span;
    onIntroSpanChange:(span?:Span)=>void;
}

class DescriptionPanel extends Component<DescriptionPanelProps> {

    public render() {
        const { text, introSpan, onIntroSpanChange, ...props } = this.props;
        return (
            <SentencePanel 
            title="Description" 
            summary={text}
            text={text}
            tagTypes={["Intro"]}
            onTag={ 
                (type,span) => {
                    onIntroSpanChange(span);
                }
            } 
            {...props} >
                {introSpan && <SentencePanelItem
                type="Intro"
                text={text}
                span={introSpan}
                helpText="Span of text that is always read as the recipe intro."
                onDelete={
                    () => {
                        onIntroSpanChange(undefined);
                    }
                }
                    />}
                    
            </SentencePanel>
        )
    }
}

export default DescriptionPanel;