import * as React from 'react';
import { Component } from 'react';
import SentencePanel, {ExpansiblePanelProps, TextEditablePanelProps} from './SentencePanel';
import { RecipeGenerationTypes } from 'recipe-generation';
import {renderSpan} from './items/SentencePanelItem';
import QuantityPanelItem from './items/QuantityPanelItem';

interface YieldPanelProps extends ExpansiblePanelProps, TextEditablePanelProps  {
    yieldQuantity?:RecipeGenerationTypes.Quantity
    onYieldChange:(yieldQuantity?:RecipeGenerationTypes.Quantity)=>void;
}

class YieldPanel extends Component<YieldPanelProps> {

    public render() {

        const { text, yieldQuantity, onYieldChange, ...props } = this.props;

        let tagTypes = ["Quantity"];

        if(yieldQuantity){
            tagTypes.push("Unit");
        }

        return (
            <SentencePanel 
            title="Yield" 
            summary={text}
            text={text}
            tagTypes={tagTypes}
            onTag={ 
                (type,span) => {
                    if(type === "Quantity"){
                        let existingUnit = undefined;
                        if(yieldQuantity){
                            existingUnit = yieldQuantity.unit;
                        }
                        onYieldChange({
                            span:span,
                            quantity:parseInt(renderSpan(text,span)),
                            unit:existingUnit
                        });
                    } else if(yieldQuantity && type === "Unit"){
                        onYieldChange({
                            span: yieldQuantity.span,
                            quantity:yieldQuantity.quantity,
                            unit:{
                                span:span,
                                singular:renderSpan(text,span),
                                plural:renderSpan(text,span)
                            }
                        });
                    }
                }
            }
            {...props}>
                {yieldQuantity &&
                    <QuantityPanelItem 
                    text={text}
                    label="Quantity"
                    quantity={yieldQuantity}
                    helpText="Span of text containing the default yield as an integer for the recipe (the yield used in the original text)."
                    unitHelpText="The span of text containing the unit of the default yield (people, meatloaves, pancakes, etc.)"
                    onQuantityChange={onYieldChange}
                    onDelete={
                        () => {
                            onYieldChange(undefined);
                        }
                    }
                    {...props}
                /> }
            </SentencePanel>
        )
    }

}

export default YieldPanel;