import * as React from 'react';
import { Component } from 'react';
import { RecipeGenerationTypes } from 'recipe-generation';
import SentencePanelItem from './SentencePanelItem';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from '@material-ui/core';
import { DeletablePanelProps } from '../SentencePanel';

interface NounPanelItemProps extends DeletablePanelProps {
    text:string;
    label:string;
    helpText:string;
    noun:RecipeGenerationTypes.NounTag;
    onNounChange:(noun:RecipeGenerationTypes.NounTag)=>void;
}

class NounPanelItem extends Component<NounPanelItemProps> {

    constructor(props: NounPanelItemProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveDialog = this.handleSaveDialog.bind(this);
    }

    public state = {
        openDialogKey: "",
        noun:JSON.parse(JSON.stringify(this.props.noun)),
    };

    componentWillReceiveProps(nextProps: NounPanelItemProps) {
        this.setState({
            noun:JSON.parse(JSON.stringify(nextProps.noun)),
        });
    }

    render() {
        const { text, label, helpText, noun, ...props } = this.props;

        let stateCurrentNoun = this.state.noun;

        return (
            <React.Fragment>
                <SentencePanelItem
                    type={label}
                    text={text}
                    span={noun.span}
                    summary={"Singular : "+noun.singular+" / Plural : "+noun.plural}
                    onEdit={() => this.handleOpenDialog("noun-edit")}
                    {...props} />
                <Dialog open={this.state.openDialogKey === "noun-edit"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{label}</DialogTitle>
                    <DialogContent>
                        <DialogContentText key="help">Enter a singular and plural form of the {label}.</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"singular"}
                            key={"singular-textfield"}
                            value={stateCurrentNoun.singular}
                            type="text"
                            label="Singular"
                            fullWidth
                            onChange={
                                (element) => {
                                    let newNoun = JSON.parse(JSON.stringify(stateCurrentNoun));
                                    newNoun.singular = element.target.value;
                                    this.setState({noun : newNoun})
                            }}/>
                        <TextField
                            margin="dense"
                            id={"plural"}
                            key={"plural-textfield"}
                            value={stateCurrentNoun.plural}
                            type="text"
                            label="Plural"
                            fullWidth
                            onChange={
                                (element) => {
                                    let newNoun = JSON.parse(JSON.stringify(stateCurrentNoun));
                                    newNoun.plural = element.target.value;
                                    this.setState({noun : newNoun})
                            }}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSaveDialog} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.resetState();
    }

    private handleSaveDialog() {
        this.props.onNounChange(this.state.noun);
        this.resetState();
    }

    private resetState() {
        this.setState({ openDialogKey: "",
        noun:this.props.noun });
    }

}

export default NounPanelItem;