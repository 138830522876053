import * as React from 'react';
import { match } from 'react-router-dom';
import { RecipeGenerationTypes } from 'recipe-generation';

import { AppState } from '../store';
import { APIClient } from '../apiclient';
import { ThunkDispatch } from 'redux-thunk';
import { loadImport, publishImport, saveImport, uploadImage } from '../store/imports/actions';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { createStyles, withStyles, Theme, WithStyles} from '@material-ui/core';
import RecipeEditForm from '../components/RecipeEditForm';
import { ImageUploadPath } from '../store/imports/types';

const styles = (theme: Theme) => createStyles({
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    button: {
        margin: theme.spacing(1),
    },
    tagGroupSection: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
});

interface ImportParams {
    id: string;
}

interface ImportProps  extends WithStyles<typeof styles> {
    required: string;
    match?: match<ImportParams>;
    loading:boolean;
    saving:boolean;
    saveStatus?:string;
    uploadingImages:ImageUploadPath[];
    recipeImport?:RecipeGenerationTypes.RenderedRecipeImport;
    test:Date;
    reload: (id:string) => void;
    publish: (id:string) => void;
    save:(id:string, original?:RecipeGenerationTypes.RawRecipe, tags?:RecipeGenerationTypes.RecipeTags) => void;
    uploadImage:(path:ImageUploadPath,file:File)=>void;
}


class Import extends React.Component<ImportProps> {
    
    public componentDidMount() {
        if(this.props.match){
            this.props.reload(this.props.match.params.id);
        }
    }

    public render() {
        if(this.props.loading){
            return <h3>Loading...</h3>
        } else {
            if(this.props.match && this.props.recipeImport !== undefined){
                const {recipeImport} = this.props;
                const id = this.props.match.params.id;
                return (
                    <RecipeEditForm 
                        id = {this.props.match.params.id}
                        recipe={recipeImport}
                        saving={this.props.saving}
                        saveStatus={this.props.saveStatus}
                        uploadingImages={this.props.uploadingImages}
                        publish={ () => this.props.publish(id) }
                        save={ (original,tags) => this.props.save(id, original, tags) }
                        uploadImage={this.props.uploadImage} />
                );
            } else {
                return <h3>Error</h3>
            }
        }

    }
}

const mapStateToProps = (state:AppState) => ({
    loading:state.imports.loadingCurrentImport,
    recipeImport: state.imports.currentImport,
    saving:state.imports.saving,
    saveStatus: state.imports.saveStatus,
    uploadingImages:state.imports.uploadingImages,
    test:new Date()
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState,APIClient,AnyAction>) => ({
    reload: (id:string) => dispatch(loadImport(id)),
    publish:(id:string) => dispatch(publishImport(id)),
    save:(id:string, original?:RecipeGenerationTypes.RawRecipe, tags?:RecipeGenerationTypes.RecipeTags) => dispatch(saveImport(id,original,tags)),
    uploadImage:(path:ImageUploadPath,file:File)=> dispatch(uploadImage(path,file))
});

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Import));