import { RecipeRendering } from 'recipe-rendering';
import { Recipe } from 'recipe-core';

export interface RecipesState {
    loading:boolean;
    recipes:RecipeRendering.RenderedRecipe[];
    loadingCurrentRecipe:boolean;
    currentRecipe?:Recipe;
}

export const LOAD_RECIPES_SUCCESS = "LOAD_RECIPES_SUCCESS";

export const LOAD_RECIPE_START = "LOAD_RECIPE_START";
export const LOAD_RECIPE_SUCCESS = "LOAD_RECIPE_SUCESS";
export const LOAD_RECIPE_FAILED = "LOAD_RECIPE_FAILED";


interface LoadRecipesSuccessAction {
    type: typeof LOAD_RECIPES_SUCCESS;
    payload: RecipeRendering.RenderedRecipe[];
}

interface LoadRecipeStartAction {
    type: typeof LOAD_RECIPE_START;
    id: string;
}

interface LoadRecipeSuccessAction {
    type: typeof LOAD_RECIPE_SUCCESS;
    payload: Recipe;
}

interface LoadRecipeFailedAction {
    type: typeof LOAD_RECIPE_FAILED;
    id: string;
}

export type RecipesActionTypes = LoadRecipesSuccessAction | LoadRecipeStartAction | LoadRecipeSuccessAction | LoadRecipeFailedAction;
