import * as React from 'react';
import { Component } from 'react';
import SentencePanel, {ExpansiblePanelProps} from './SentencePanel';

interface TitlePanelProps extends ExpansiblePanelProps  {
    title: string;
    onTitleChange:(title:string)=>void;
}

class SectionTitlePanel extends Component<TitlePanelProps> {

    public render() {
        const { title, ...props } = this.props;
        return (
            <SentencePanel 
            title="Section Title" 
            summary={this.props.title}
            text={this.props.title}
            tagTypes={[]}
            onTag={ (type,span) => console.log("type" + type + JSON.stringify(span)) }
            onTextChange={ this.props.onTitleChange }
            {...props}>
            </SentencePanel>
        )
    }

}

export default SectionTitlePanel;