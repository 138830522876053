import { RecipeGenerationTypes } from "recipe-generation";
import { RecipeRendering } from "recipe-rendering";
import * as shortid from 'shortid';

class ChangeObservingProxyHandler<T extends object> implements ProxyHandler<T> {

    private static readonly IGNORED = ['toJSON','prototype', 'splice', 'length', 'constructor'];

    onChange:(path:PropertyKey[])=>void
    

    constructor(onChange:(path:PropertyKey[])=>void){
        this.onChange = onChange;
    }

    get(target: any, 
        p: PropertyKey, 
        receiver: any): any {
            //TODO: find a better way to ignore props we don't
            if(p in ChangeObservingProxyHandler.IGNORED){
                return Reflect.get(target, p, receiver);
            }
        try {
            return new Proxy(target[p], new ChangeObservingProxyHandler((pathSuffix) =>  this.onChange([p].concat(pathSuffix)) ));
        }
        catch {
            return Reflect.get(target, p, receiver);
        }
    }

    set(target: any, 
        p: PropertyKey, 
        value: any, 
        receiver: any): boolean {
        this.onChange([p]);
        return Reflect.set(target, p, value, receiver);
    }

    defineProperty? (target: T, p: PropertyKey, attributes: PropertyDescriptor): boolean {
        this.onChange([p]);
        return Reflect.defineProperty(target, p, attributes);
    }

    deleteProperty? (target: any, p: PropertyKey): boolean {
        this.onChange([p]);
        return Reflect.deleteProperty(target, p);
    }
};

interface Transaction {
    commit:()=>void;
}

export class MutableRecipe implements RecipeGenerationTypes.RenderedRecipeImport {

    recipe:RecipeGenerationTypes.RenderedRecipeImport;
    stepTagsById:Map<string,RecipeGenerationTypes.StepTag>;
    stepTagsIndicesById:Map<string,number>;

    ingredientTagsById:Map<string,RecipeGenerationTypes.IngredientTag>;
    ingredientTagsIndicesById:Map<string,number>;

    private saveHandler: (original?: RecipeGenerationTypes.RawRecipe, tags?:RecipeGenerationTypes.RecipeTags) => void;

    public constructor (recipe:RecipeGenerationTypes.RenderedRecipeImport,
        save: (original?: RecipeGenerationTypes.RawRecipe, tags?:RecipeGenerationTypes.RecipeTags) => void){
        this.recipe = recipe;
        this.saveHandler = save;
        var stepTagsById = new Map<string,RecipeGenerationTypes.StepTag>();
        var stepTagsIndicesById = new Map<string,number>();

        recipe.tags.steps.forEach((tag,index) => {
            stepTagsById.set(tag.sentenceId,tag);
            stepTagsIndicesById.set(tag.sentenceId,index);
        });
        this.stepTagsById = stepTagsById;
        this.stepTagsIndicesById = stepTagsIndicesById;


        var ingredientTagsById = new Map<string,RecipeGenerationTypes.IngredientTag>();
        var ingredientTagsIndicesById = new Map<string,number>();

        recipe.tags.ingredients.forEach((tag,index) => {
            ingredientTagsById.set(tag.id,tag);
            ingredientTagsIndicesById.set(tag.id,index);
        });

        this.ingredientTagsById = ingredientTagsById;
        this.ingredientTagsIndicesById = ingredientTagsIndicesById;
    }

    get id(): string {
        return this.recipe.id;
    }

    get version(): string {
        return this.recipe.version;
    }

    get original(): RecipeGenerationTypes.RawRecipe {
        return this.recipe.original;
    }

    get source(): RecipeGenerationTypes.Source {
        return this.recipe.source;
    }

    get tags(): RecipeGenerationTypes.RecipeTags {
        return this.recipe.tags;
    }

    get renderedRecipe (): RecipeRendering.RenderedRecipe {
        return this.renderedRecipe;
    }

    public getMutableStepTag(sentenceId:string):MutableStepTag{
        let existingTag = this.stepTagsById.get(sentenceId);
        let index = this.stepTagsIndicesById.get(sentenceId);
        if (index !== undefined && existingTag !== undefined) {
            return new MutableStepTag(existingTag, 
                (tag)=>{
                //We can trust the index since we only push new tags
                this.save(recipe=>{
                    //@ts-ignore
                    recipe.tags.steps[index] = tag;
                });
            });
        }
        //We create a new tag
        return new MutableStepTag({id:shortid.generate().toString(), sentenceId:sentenceId}, (tag)=>{
            this.stepTagsIndicesById.set(sentenceId, this.recipe.tags.steps.length);
            this.stepTagsById.set(sentenceId, tag);
            this.save(recipe=>{
                recipe.tags.steps.push(tag);
            });
        });
    }

    public getMutableIngredientTag(id:string):MutableIngredientTag{
        let existingTag = this.ingredientTagsById.get(id);
        let index = this.ingredientTagsIndicesById.get(id);
        if (index !== undefined && existingTag !== undefined) {
            return new MutableIngredientTag(existingTag, 
                (tag)=>{
                //We can trust the index since we only push new tags
                this.save(recipe=>{
                    //@ts-ignore
                    recipe.tags.ingredients[index] = tag;
                });
            });
        }
        //We create a new tag
        return new MutableIngredientTag({id:id}, (tag)=>{
            this.ingredientTagsIndicesById.set(id, this.recipe.tags.steps.length);
            this.ingredientTagsById.set(id, tag);
            this.save(recipe=>{
                recipe.tags.ingredients.push(tag);
            });
        });
    } 

    public ingredientTagIndex(id:string) : number | undefined {
        return this.ingredientTagsIndicesById.get(id);
    }

    public tagIndex(sentenceId:string) : number | undefined {
        return this.stepTagsIndicesById.get(sentenceId);
    }

    public makeTransaction():RecipeTransaction {
        return new RecipeTransaction(this.recipe, this.saveHandler);
    }

    public save(handler:(recipe:RecipeGenerationTypes.RenderedRecipeImport)=>void){
        let transaction = this.makeTransaction();
        handler(transaction.recipe);
        transaction.commit();
    }

}

export class MutableIngredientTag implements RecipeGenerationTypes.IngredientTag {

    tag:RecipeGenerationTypes.IngredientTag
    private saveHandler: (tag:RecipeGenerationTypes.IngredientTag) => void;

    public constructor (tag:RecipeGenerationTypes.IngredientTag,
        save: (tag:RecipeGenerationTypes.IngredientTag) => void){
        this.tag = tag;
        this.saveHandler = save;
    }

    get id():string{
        return this.tag.id;
    }

    get name():RecipeGenerationTypes.NounTag | undefined{
        return this.tag.name;
    }

    get quantity():RecipeGenerationTypes.Quantity | undefined{
        return this.tag.quantity;
    }

    get preparationSpan():RecipeGenerationTypes.Span | undefined {
        return this.tag.preparationSpan;
    }

    public makeTransaction():IngredientTagTransaction {
        return new IngredientTagTransaction(this.tag, this.saveHandler);
    }

    public save(handler:(tag:RecipeGenerationTypes.IngredientTag)=>void){
        let transaction = this.makeTransaction();
        handler(transaction.tag);
        transaction.commit();
    }

}

export class MutableStepTag implements RecipeGenerationTypes.StepTag {

    tag:RecipeGenerationTypes.StepTag
    private saveHandler: (tag:RecipeGenerationTypes.StepTag) => void;

    public constructor (tag:RecipeGenerationTypes.StepTag,
        save: (tag:RecipeGenerationTypes.StepTag) => void){
        this.tag = tag;
        this.saveHandler = save;
    }

    get id():string{
        return this.tag.id;
    }
    get sentenceId(): string{
        return this.tag.sentenceId;
    }
    get shortTextSpan(): RecipeGenerationTypes.Span | undefined {
        return this.tag.shortTextSpan;
    }

    get topicSpan(): RecipeGenerationTypes.Span | undefined {
        return this.tag.topicSpan;
    }
    get topicSource(): RecipeGenerationTypes.Direction | undefined {
        return this.tag.topicSource;
    }

    get loop(): RecipeGenerationTypes.LoopTag | undefined {
        return this.tag.loop;
    }

    get scalableIngredients(): RecipeGenerationTypes.ScalableIngredients[] | undefined {
        return this.tag.scalableIngredients;
    }

    get quantities(): RecipeGenerationTypes.Quantity[] | undefined {
        return this.tag.quantities;
    }

    get complements(): RecipeGenerationTypes.Direction | undefined {
        return this.tag.complements;
    }
    get timer(): RecipeGenerationTypes.TimerTag | undefined {
        return this.tag.timer;
    }
    get completesStep(): string | undefined {
        return this.tag.completesStep;
    }

    get dependencies(): string[] | undefined {
        return this.tag.dependencies;
    }

    public makeTransaction():StepTagTransaction {
        return new StepTagTransaction(this.tag, this.saveHandler);
    }

    public save(handler:(tag:RecipeGenerationTypes.StepTag)=>void){
        let transaction = this.makeTransaction();
        handler(transaction.tag);
        transaction.commit();
    }

}

export class RecipeTransaction implements Transaction {

    public recipe:RecipeGenerationTypes.RenderedRecipeImport;

    private save:(original?: RecipeGenerationTypes.RawRecipe, tags?:RecipeGenerationTypes.RecipeTags) => void
    private mutatedOriginal = false;
    private mutatedTags = false;

    constructor(recipe:RecipeGenerationTypes.RenderedRecipeImport,
        save: (original?: RecipeGenerationTypes.RawRecipe, tags?:RecipeGenerationTypes.RecipeTags) => void){
            this.save = save;
        const recipeCopy = JSON.parse(JSON.stringify(recipe));
        this.recipe = new Proxy(recipeCopy, new ChangeObservingProxyHandler(
            (path) => {
                if(path[0] === "original"){
                    this.mutatedOriginal = true;
                }
                else if(path[0] === "tags"){
                    this.mutatedTags = true;
                } else {
                    console.log(path);
                }
            }
        ));;
    }

    public commit() {
        let original = JSON.parse(JSON.stringify(this.recipe.original));
        let tags = JSON.parse(JSON.stringify(this.recipe.tags));
        this.save(original, tags);
        return;

        if(this.mutatedOriginal && this.mutatedTags){
            console.log("Committed both mutation");
            this.save(original, tags);
        }
        else if (this.mutatedOriginal) {
            console.log("Committed original mutation");
            this.save(original,undefined);
        }
        else if (this.mutatedTags) {
            console.log("Committed original mutation");
            this.save(undefined, tags);
        } else {
            console.log("Committed without any mutation");
        }
    }

}

export class StepTagTransaction implements Transaction {

    public tag:RecipeGenerationTypes.StepTag
    private save: (tag:RecipeGenerationTypes.StepTag) => void;

    constructor(tag:RecipeGenerationTypes.StepTag,
        save: (tag:RecipeGenerationTypes.StepTag) => void){
            this.save = save;
        const tagCopy = JSON.parse(JSON.stringify(tag));
        this.tag = tagCopy;
    }

    public commit() {
        let tag = JSON.parse(JSON.stringify(this.tag));
        this.save(tag);
    }

}

export class IngredientTagTransaction implements Transaction {

    public tag:RecipeGenerationTypes.IngredientTag
    private save: (tag:RecipeGenerationTypes.IngredientTag) => void;

    constructor(tag:RecipeGenerationTypes.IngredientTag,
        save: (tag:RecipeGenerationTypes.IngredientTag) => void){
            this.save = save;
        const tagCopy = JSON.parse(JSON.stringify(tag));
        this.tag = tagCopy;
    }

    public commit() {
        let tag = JSON.parse(JSON.stringify(this.tag));
        this.save(tag);
    }

}

