import * as React from 'react';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, withStyles, createStyles, Theme, WithStyles, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import { loadRecipes } from '../store/recipes/actions';
import { ThunkDispatch } from 'redux-thunk';
import { APIClient } from '../apiclient';
import { RecipeRendering } from 'recipe-rendering';
import { withRouter, RouteComponentProps } from 'react-router';
import CodeIcon from '@material-ui/icons/Code';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

interface TableProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
  recipes: RecipeRendering.RenderedRecipe[]
}

class RecipesTable extends React.Component<TableProps> {

  public render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Recipe Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.recipes.map(recipe => (
              <TableRow key={recipe.name}>
                <TableCell component="th" scope="row">
                  {recipe.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton onClick={() => this.handleOnClick(recipe.id)}>
                    <CodeIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }

  private handleOnClick(id: string) {
    this.props.history.push('/recipes/' + id);
  }
}

const StyledRecipesTable = withRouter(withStyles(styles)(RecipesTable));

interface RecipesProps {
  recipes: RecipeRendering.RenderedRecipe[];
  reload: () => void;
}

class Recipes extends React.Component<RecipesProps> {

  public componentDidMount() {
    this.props.reload();
  }

  public render() {
    return (
      <div>
        <h2>Recipes</h2>
        <StyledRecipesTable recipes={this.props.recipes} />
      </div>
    );
  };
}


const mapStateToProps = (state: AppState) => ({
  recipes: state.recipes.recipes
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, APIClient, AnyAction>) => ({
  reload: () => dispatch(loadRecipes())
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipes);