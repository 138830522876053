import * as React from 'react';
import { Component } from 'react';
import { ExpansiblePanelProps, ImageUploadingPanelProps, DeletablePanelProps } from './SentencePanel';
import SentencePanel from './SentencePanel';
import SentencePanelItem from './items/SentencePanelItem';
import { RecipeGenerationTypes } from 'recipe-generation';
import * as shortid from 'shortid';
import QuantityPanelItem from './items/QuantityPanelItem';

interface TitlePanelProps extends ExpansiblePanelProps, ImageUploadingPanelProps, DeletablePanelProps {
    title: string;
    shortNameSpan?: RecipeGenerationTypes.Span;
    synonymTags?: RecipeGenerationTypes.SynonymTag[];
    order?:number;
    onTitleChange: (title: string) => void;
    onOrderChange: (order?: number) => void;
    onShortNameSpanChange: (shortNameSpan?: RecipeGenerationTypes.Span) => void;
    onAddSynonymTag: (synonymTag: RecipeGenerationTypes.SynonymTag) => void;
    onRemoveSynonymTag: (index:number) => void;
}

class TitlePanel extends Component<TitlePanelProps> {

    public static readonly SYNONYM = "Synonym";
    public static readonly SHORT_NAME = "Short Name";

    public render() {
        const { title, shortNameSpan,synonymTags, ...props } = this.props;
        return (
            <SentencePanel
                title="Name"
                summary={title}
                text={title}
                tagTypes={[TitlePanel.SYNONYM, TitlePanel.SHORT_NAME]}
                onTag={
                    (type, span) => {
                        switch(type){
                            case TitlePanel.SYNONYM:
                                this.props.onAddSynonymTag({
                                    id:shortid.generate(),
                                    span:span
                                })
                                break;
                            case TitlePanel.SHORT_NAME:
                                this.props.onShortNameSpanChange(span);
                                break;
                        }
                    }
                }
                onTextChange={this.props.onTitleChange}
                {...props} >

                    {shortNameSpan && <SentencePanelItem 
                    key = "short-name"
                    type={TitlePanel.SHORT_NAME} 
                    text={title} 
                    span={shortNameSpan} 
                    helpText='A shorter name for the recipe.'
                    onDelete={
                        () => {
                            this.props.onShortNameSpanChange(undefined);
                        }
                    } />}
                    
                    {synonymTags && synonymTags.length > 0 && synonymTags.map((tag,index) => (
                        <SentencePanelItem 
                        key = {"synonym-"+index.toString()}
                        type={TitlePanel.SYNONYM} 
                        text={title} 
                        span={tag.span} 
                        helpText='An alternative name for the recipe to be triggered. Warning : If a synonym is too ambiguous, the result will be undefined.'
                        onDelete={
                            () => {
                                this.props.onRemoveSynonymTag(index);
                            }
                        } />
                    ))}
                    
                
            </SentencePanel>
        )
    }
}

export default TitlePanel;