import * as React from 'react';
import { Component } from 'react';
import { TableRow, TableCell, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { RecipeGenerationTypes } from 'recipe-generation';

interface SentencePanelItemProps {
    type:string;
    text:string;
    span?:RecipeGenerationTypes.Span;
    summary?:string;
    helpText?:string;
    onEdit?:()=>void;
    onDelete?:()=>void;
}

class SentencePanelItem extends Component<SentencePanelItemProps>  {

    constructor(props:SentencePanelItemProps){
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    public state = {
        openDialogKey: ""
    };


    public render() {

        return (
            <React.Fragment>
            <TableRow>
                <TableCell>{this.props.type}</TableCell>
                <TableCell>{this.props.span ? renderSpan(this.props.text,this.props.span) : this.props.text}</TableCell>
                <TableCell>{this.props.summary}</TableCell>
                <TableCell>
                    <IconButton onClick={() => this.handleOpenDialog("info")}> 
                      <InfoIcon />
                    </IconButton>
                    {this.props.onEdit && 
                        <IconButton onClick={this.props.onEdit}> 
                            <EditIcon />
                        </IconButton>
                    }
                    {this.props.onDelete && 
                        <IconButton onClick={this.props.onDelete}> 
                        <DeleteIcon />
                        </IconButton>
                    }

                  </TableCell>
            </TableRow>
                {this.props.helpText && 
                <Dialog open={this.state.openDialogKey === "info"}
                onClose={this.handleCloseDialog}
                aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.type}</DialogTitle>
                    <DialogContent>
                        {this.props.helpText}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>}
            </React.Fragment>

        )
    }

    private handleOpenDialog(key:string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.resetState();
    }

    private resetState() {
        this.setState({ openDialogKey: ""});
    }

}

export function renderSpan(text:string, span:RecipeGenerationTypes.Span):string{
    return text.slice(span.index,span.index+span.length);
}

export default SentencePanelItem;