import * as React from 'react';
import './App.css';

import * as firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from 'firebaseui'
import {
  FirebaseAuthProvider, IfFirebaseAuthedAnd, IfFirebaseUnAuthed 
} from "@react-firebase/auth";

import { config } from './firebase-config';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import blue from '@material-ui/core/colors/blue';
import { createMuiTheme, createStyles, Theme, withStyles, WithStyles, Button } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';
import Navigation from './Navigation';
import Recipes from './pages/Recipes';
import Imports from './pages/Imports';
import Home from './pages/Home';
import { Provider } from 'react-redux';
import configureStore from './store';
import { APIClient } from './apiclient';
import { FirebaseAuth } from './auth/firebase-auth';
import Import from './pages/Import';
import { ThemeProvider } from '@material-ui/styles';
import Creations from './pages/Creations';
import Creation from './pages/Creation';
import RecipeComponent from './pages/Recipe';

const appTheme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink
  }
})

const styles = (theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  }
});

firebase.initializeApp(config);

var uiConfig = {
  signInSuccessUrl: process.env.REACT_APP_ROUTER_BASENAME,
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '<your-tos-url>',
  // Privacy policy url/callback.
  privacyPolicyUrl: function() {
    window.location.assign('<your-privacy-policy-url>');
  }
};

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());
// The start method will wait until the DOM is loaded.
ui.start('#firebaseui-auth-container', uiConfig);

const firebaseAuth = new FirebaseAuth(firebase.auth());

declare global {
  interface Window { API_ROOT: string; }
}

let apiRoot = window.API_ROOT;

if(process.env.REACT_APP_API_URL !== undefined){
  apiRoot = process.env.REACT_APP_API_URL;
}

const client = new APIClient(apiRoot,firebaseAuth);

const store = configureStore(client);

interface Props extends WithStyles<typeof styles> {

}

class App extends React.Component<Props> {
  public render() {
    const { classes } = this.props;
    return (
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <Provider store={store}>
        <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
            <ThemeProvider theme={appTheme}>
              <IfFirebaseAuthedAnd filter={({ providerId, user }) => {
    if(!user.email){return false;}
    return (
      providerId !== "anonymous" &&
      (user.email === "ed@laveryplante.com" || user.email === "jason.muscat@gmail.com")
    );
  }}
  >
 {({ isSignedIn, user, providerId }) => {
return (
  <div>
  <Navigation />
  <main className={classes.content}>
 <Route path="/" exact component={Home} />
 <Route exact path="/recipes/" component={Recipes} />
 <Route path="/recipes/:id" component={RecipeComponent} />
 <Route exact path="/creations/" component={Creations} />
 <Route exact path="/imports/" component={Imports} />
 <Route path={`/imports/:id`} component={Import} />
 <Route path={`/creations/:id`} component={Creation} />
  </main>
  </div>
);
 }}
                </IfFirebaseAuthedAnd>
                <IfFirebaseAuthedAnd filter={({ providerId, user }) => {
    if(!user.email){return true;}
    return (
      user.email !== "ed@laveryplante.com" && user.email !== "jason.muscat@gmail.com" 
    );
  }}
  >
 {({ isSignedIn, user, providerId }) => {
return (
  <div>
  <main className={classes.content}>
    <p>Unauthorized</p>
    <Button onClick={()=>{firebase.auth().signOut()}}>Log out</Button>
  </main>
  </div>
);
 }}
                </IfFirebaseAuthedAnd>
                <IfFirebaseUnAuthed>
                {() => {
                  return (
                    <div id="firebaseui-auth-container"/>
                  );
                }}
                </IfFirebaseUnAuthed>
            </ThemeProvider>
        </Router>
        </Provider>
      </FirebaseAuthProvider>
    );
  }
}

export default withStyles(styles)(App);
