import * as React from 'react';
import { Component } from 'react';
import { RecipeGenerationTypes } from 'recipe-generation';
import SentencePanelItem from './SentencePanelItem';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from '@material-ui/core';

interface CategoryPanelItemProps {
    type:string;
    helpText:string;
    text: string;
    span?: RecipeGenerationTypes.Span;
    categoryLabel:string;
    categoryHelpText:string;
    supportedCategories:number[];
    currentCategory?:number;

    labels:string[];
    defaultLabel:string;
    onCategoryChange:(value?:number)=>void;
    onDelete?: () => void;
}

class CategoryPanelItem extends Component<CategoryPanelItemProps> {

    constructor(props: CategoryPanelItemProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveDialog = this.handleSaveDialog.bind(this);
    }

    public state = {
        openDialogKey: "",
        currentCategory:this.props.currentCategory,
    };

    componentWillReceiveProps(nextProps: CategoryPanelItemProps) {
        this.setState({
            currentCategory:nextProps.currentCategory,
        });
    }

    render() {
        const { categoryLabel, categoryHelpText, supportedCategories, currentCategory, labels, defaultLabel, onCategoryChange, ...props } = this.props;

        let stateCurrentCategory = this.state.currentCategory;

        var label = defaultLabel;
        if(stateCurrentCategory !== undefined && stateCurrentCategory > -1){
            label = labels[stateCurrentCategory];
        }

        return (
            <React.Fragment>
                <SentencePanelItem
                    summary={label}
                    onEdit={() => this.handleOpenDialog("category-edit")}
                    {...props} />
                <Dialog open={this.state.openDialogKey === "category-edit"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{categoryLabel}</DialogTitle>
                    <DialogContent>
                        <DialogContentText key="help">{categoryHelpText}</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"category"}
                            select
                            key={"category-textfield"}
                            value={stateCurrentCategory !== undefined ? stateCurrentCategory : -1}
                            onChange={(element) => {
                                let value = parseInt(element.target.value);
                                if(value === -1){
                                    this.setState({ currentCategory: undefined });
                                } else {
                                    this.setState({ currentCategory: value });
                                }
                            }}
                            SelectProps={{
                                native: true
                            }}>
                            <option key="null" value={-1}>
                            {defaultLabel}
                            </option>
                          {supportedCategories.map((value,index)=>(
                            <option key={value} value={index}>
                            {labels[index]}
                            </option>
                          ))}
                          
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSaveDialog} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.resetState();
    }

    private handleSaveDialog() {
        this.props.onCategoryChange(this.state.currentCategory);
        this.resetState();
    }

    private resetState() {
        this.setState({ openDialogKey: "",
        currentCategory:this.props.currentCategory });
    }

}

export default CategoryPanelItem;