import * as React from 'react';
import { Component } from 'react';
import TitlePanel from './panels/TitlePanel';
import { Tooltip, Fab, WithStyles, withStyles, Theme, Menu, MenuItem, Button, Typography, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { createStyles } from '@material-ui/styles';
import * as shortid from 'shortid';

import SectionTitlePanel from './panels/SectionTitlePanel';
import StepPanel, { IngredientReference } from './panels/StepPanel';
import EquipmentPanel from './panels/EquipmentPanel';
import IngredientPanel from './panels/IngredientPanel';
import { RecipeGenerationTypes } from 'recipe-generation';
import DescriptionPanel from './panels/DescriptionPanel';
import { MutableRecipe } from '../store/MutableRecipe';
import YieldPanel from './panels/YieldPanel';
import { ImageUploadPath } from '../store/imports/types';

const SentencePanelStyles = (theme: Theme) => createStyles({
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    button: {
        margin: theme.spacing(1),
    },
    fabwrapper: {
        margin: theme.spacing(2),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
        display: 'flex',
    },
    fab: {
        margin : theme.spacing(1)
    },
    fabProgress: {
        position: 'absolute',
        top:0,
        left: 0,
        zIndex: 1,
    },
    section: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
});


interface RecipeEditFormProps extends WithStyles<typeof SentencePanelStyles> {
    id: string;
    recipe: RecipeGenerationTypes.RenderedRecipeImport;
    saving: boolean;
    saveStatus?: string;
    uploadingImages: ImageUploadPath[];
    publish: () => void;
    save: (original?: RecipeGenerationTypes.RawRecipe, tags?: RecipeGenerationTypes.RecipeTags) => void;
    uploadImage: (path: ImageUploadPath, file: File) => void;
}


enum AddElementType {
    Ingredient,
    Equipment,
    Section,
    Step
}

class RecipeEditForm extends Component<RecipeEditFormProps> {

    public state = {
        expandedKey: null,
        dialogElementType: null,
        addMenuAnchorElement: null,
        textBuffer: undefined,
        sectionId: undefined
    }

    constructor(props: any) {
        super(props);
        this.handleOpenAddMenu = this.handleOpenAddMenu.bind(this);
        this.handleCloseAddMenu = this.handleCloseAddMenu.bind(this);
        this.handleAddElement = this.handleAddElement.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveDialog = this.handleSaveDialog.bind(this);
    }

    public render() {


        const { classes } = this.props;
        const recipe = new MutableRecipe(this.props.recipe, this.props.save);

        const flatSentences: RecipeGenerationTypes.RawRecipeSentence[] = [];
        const sentencesById = new Map<string, RecipeGenerationTypes.RawRecipeSentence>();
        for (let stepSection of recipe.original.stepSections) {
            for (let step of stepSection.steps) {
                for (let sentence of step.sentences) {
                    flatSentences.push(sentence);
                    sentencesById.set(sentence.id, sentence);
                }
            }
        }

        let ingredientRefs: IngredientReference[] = [];

        for (let ingredient of recipe.original.ingredients) {
            let index = recipe.ingredientTagIndex(ingredient.id);
            let tag = undefined;
            if (index !== undefined) {
                tag = recipe.tags.ingredients[index];
            }
            if (tag && tag.name) {
                if (tag.quantity && tag.quantity.unit) {
                    ingredientRefs.push({
                        id: ingredient.id,
                        singular: tag.quantity.unit.singular + " of " + tag.name.plural,
                        plural: tag.quantity.unit.plural + " of " + tag.name.plural,
                    });
                } else {
                    ingredientRefs.push({
                        id: ingredient.id,
                        singular: tag.name.singular,
                        plural: tag.name.plural,
                    });
                }
            } else {
                ingredientRefs.push({
                    id: ingredient.id,
                    singular: ingredient.text,
                    plural: ingredient.text
                });
            }
        }


        return (
            <React.Fragment>
                <div className={classes.header}>
                    <Typography variant="h5" gutterBottom>{recipe.original.name}</Typography>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.props.publish}>Publish</Button>
                </div>
                <div className={classes.section}>
                    {this.props.saveStatus && <Typography variant="caption" display="block" gutterBottom> {this.props.saveStatus}</Typography>}

                    <Typography variant="h6" gutterBottom>General</Typography>

                    <TitlePanel
                        title={recipe.original.name}
                        synonymTags={recipe.tags.synonymTags}
                        order={recipe.original.order}
                        shortNameSpan={recipe.tags.shortNameSpan}
                        expanded={this.state.expandedKey === 'recipe-title'}
                        onExpandedChange={this.handleExpandedChange('recipe-title')}
                        onOrderChange={
                            (order) => {
                                recipe.save(recipe => {
                                    recipe.original.order = order;
                                });
                            }
                        }
                        onTitleChange={
                            (title) => {
                                recipe.save(recipe => {
                                    recipe.original.name = title;
                                    //Removing associated tags
                                    delete recipe.tags.shortNameSpan;
                                    delete recipe.tags.synonymTags;
                                });
                            }
                        }
                        imageHref={recipe.original.image}
                        uploadingImage={(() => {
                            for (let image of this.props.uploadingImages) {
                                if (image.type == "cover") {
                                    return true;
                                }
                            }
                            return false;
                        })()}
                        onImageUpload={
                            (file) => {
                                this.props.uploadImage({
                                    recipeId: this.props.id,
                                    type: "cover",
                                    itemId: ""
                                }, file);
                            }
                        }
                        onAddSynonymTag={
                            (tag) => {
                                recipe.save(recipe => {
                                    if (recipe.tags.synonymTags === undefined) {
                                        recipe.tags.synonymTags = [tag];
                                    } else {
                                        recipe.tags.synonymTags.push(tag);
                                    }
                                });
                            }
                        }
                        onRemoveSynonymTag={
                            (index) => {
                                recipe.save(recipe => {
                                    if (recipe.tags.synonymTags) {
                                        recipe.tags.synonymTags.splice(index, 1);
                                    }
                                });
                            }
                        }
                        onShortNameSpanChange={
                            (shortNameSpan) => {
                                recipe.save(recipe => {
                                    if (shortNameSpan) {
                                        recipe.tags.shortNameSpan = shortNameSpan;
                                    } else {
                                        delete recipe.tags.shortNameSpan;
                                    }
                                });
                            }
                        }
                    />
                    <YieldPanel
                        text={recipe.original.yield ? recipe.original.yield : ""}
                        yieldQuantity={recipe.tags.yield}
                        expanded={this.state.expandedKey === 'recipe-yield'}
                        onExpandedChange={this.handleExpandedChange('recipe-yield')}
                        onTextChange={
                            (text) => {
                                recipe.save(recipe => {
                                    recipe.original.yield = text;
                                    delete recipe.tags.yield;
                                });
                            }
                        }
                        onYieldChange={
                            (yieldQuantity) => {
                                recipe.save(recipe => {
                                    if (yieldQuantity) {
                                        recipe.tags.yield = yieldQuantity;
                                    } else {
                                        delete recipe.tags.yield;
                                    }
                                });
                            }
                        } />
                    <DescriptionPanel
                        text={recipe.original.description}
                        introSpan={recipe.tags.introSpan}
                        onIntroSpanChange={(span) => {
                            recipe.save(recipe => {
                                if (span) {
                                    recipe.tags.introSpan = span;
                                } else {
                                    delete recipe.tags.introSpan;
                                }
                            });
                        }}
                        expanded={this.state.expandedKey === 'recipe-description'}
                        onExpandedChange={this.handleExpandedChange('recipe-description')}
                        onTextChange={
                            (text) => {
                                recipe.save(recipe => {
                                    recipe.original.description = text;
                                });
                            }
                        } />

                    {recipe.original.equipment.map((equipment, index) => (
                        <EquipmentPanel
                            key={equipment.id}
                            text={equipment.text}
                            expanded={this.state.expandedKey === 'equipment-' + equipment.id}
                            onExpandedChange={this.handleExpandedChange('equipment-' + equipment.id)}
                            allEquipment={recipe.original.equipment}
                            onMoveEquipmentBefore={
                                (id) => {
                                    var newEquipmentIndex = 0;
                                    for (let beforeEquipment of recipe.original.equipment) {
                                        if (beforeEquipment.id === id) {
                                            recipe.save(recipe => {
                                                recipe.original.equipment.splice(index, 1);
                                                recipe.original.equipment.splice(newEquipmentIndex, 0, equipment);
                                            });
                                            return;
                                        }
                                        newEquipmentIndex++;
                                    }
                                }
                            }
                            onTextChange={
                                (text) => {
                                    recipe.save(recipe => {
                                        recipe.original.equipment[index].text = text;
                                    });
                                }
                            }
                            onDelete={
                                () => {
                                    recipe.save(recipe => {
                                        recipe.original.equipment.splice(index, 1);
                                    });
                                }
                            } />
                    ))}

                    {recipe.original.ingredients.map((ingredient, index) => (
                        <IngredientPanel
                            key={ingredient.id}
                            text={ingredient.text}
                            tag={recipe.getMutableIngredientTag(ingredient.id)}
                            expanded={this.state.expandedKey === 'ingredient-' + ingredient.id}
                            onExpandedChange={this.handleExpandedChange('ingredient-' + ingredient.id)}
                            allIngredients={recipe.original.ingredients}
                            onMoveIngredientBefore={
                                (id) => {
                                    var newIngredientIndex = 0;
                                    for (let beforeIngredient of recipe.original.ingredients) {
                                        if (beforeIngredient.id === id) {
                                            recipe.save(recipe => {
                                                recipe.original.ingredients.splice(index, 1);
                                                recipe.original.ingredients.splice(newIngredientIndex, 0, ingredient);
                                            });
                                            return;
                                        }
                                        newIngredientIndex++;
                                    }
                                }
                            }
                            onTextChange={
                                (text) => {
                                    recipe.save(recipe => {
                                        recipe.original.ingredients[index].text = text;
                                    });
                                }
                            }
                            deletionWarning={
                                (() => {
                                    let tagIndex = recipe.ingredientTagIndex(ingredient.id);
                                    if (tagIndex) {
                                        let tag = recipe.tags.ingredients[tagIndex];
                                        for (let stepTag of recipe.tags.steps) {
                                            if (stepTag.scalableIngredients) {
                                                for (let stepIngredient of stepTag.scalableIngredients) {
                                                    if (stepIngredient.id === tag.id) {
                                                        return "Warning : There are steps depending on this ingredient.";
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    return undefined;
                                })()
                            }
                            onDelete={
                                () => {
                                    let tagIndex = recipe.ingredientTagIndex(ingredient.id);
                                    recipe.save(recipe => {
                                        recipe.original.ingredients.splice(index, 1);
                                        if (tagIndex) {
                                            recipe.tags.ingredients.splice(tagIndex, 1);
                                        }
                                    });
                                }
                            }
                        />
                    ))}

                </div>
                {recipe.original.stepSections.map((section, sectionIndex) => (

                    <div key={"section-" + section.id} className={classes.section}>
                        <Typography variant="h6" gutterBottom>{section.name ? section.name : "Untitled Section " + (sectionIndex + 1)}</Typography>

                        <SectionTitlePanel
                            title={section.name ? section.name : ""}
                            expanded={this.state.expandedKey === 'section-title-' + section.id}
                            onExpandedChange={this.handleExpandedChange('section-title-' + section.id)}
                            onTitleChange={
                                (title) => {
                                    recipe.save(recipe => {
                                        recipe.original.stepSections[sectionIndex].name = title;
                                    });
                                }
                            } />

                        {section.steps.map((step, stepIndex) => (
                            <React.Fragment key={"step-" + step.id}>
                                {step.sentences.map((sentence, sentenceIndex) => (
                                    <StepPanel
                                        key={"step-" + sentence.id}
                                        text={sentence.text}
                                        sentences={flatSentences}
                                        ingredientRefs={ingredientRefs}
                                        tag={recipe.getMutableStepTag(sentence.id)}
                                        expanded={this.state.expandedKey === 'step-' + sentence.id}
                                        onExpandedChange={this.handleExpandedChange('step-' + sentence.id)}
                                        imageHref={sentence.image}
                                        uploadingImage={(() => {
                                            for (let image of this.props.uploadingImages) {
                                                if (image.itemId == sentence.id) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        })()}
                                        onImageUpload={
                                            (file) => {
                                                this.props.uploadImage({
                                                    recipeId: this.props.id,
                                                    type: "sentence",
                                                    itemId: sentence.id
                                                }, file);
                                            }
                                        }
                                        onMoveStepBefore={
                                            (id) => {
                                                var newSectionIndex = 0;
                                                for (let section of recipe.original.stepSections) {
                                                    var newStepIndex = 0;
                                                    for (let step of section.steps) {
                                                        var newSentenceIndex = 0;
                                                        for (let beforeSentence of step.sentences) {
                                                            if (beforeSentence.id === id) {
                                                                recipe.save(recipe => {
                                                                    recipe.original.stepSections[sectionIndex].steps[stepIndex].sentences.splice(sentenceIndex, 1);
                                                                    recipe.original.stepSections[newSectionIndex].steps[newStepIndex].sentences.splice(newSentenceIndex, 0, sentence);
                                                                });
                                                                return;
                                                            }
                                                            newSentenceIndex++;
                                                        }
                                                        newStepIndex++;
                                                    }
                                                    newSectionIndex++;
                                                }
                                            }
                                        }
                                        onTextChange={
                                            (text) => {
                                                let tagIndex = recipe.tagIndex(sentence.id);
                                                recipe.save(recipe => {
                                                    recipe.original.stepSections[sectionIndex].steps[stepIndex].sentences[sentenceIndex].text = text;
                                                    if (tagIndex) {
                                                        recipe.tags.steps.splice(tagIndex, 1);
                                                    }
                                                });
                                            }
                                        }
                                        deletionWarning={(() => {
                                            for (let stepTag of recipe.tags.steps) {
                                                if (stepTag.dependencies && stepTag.dependencies.includes(sentence.id)) {
                                                    return "Warning : There are steps depending on this step. Deleting this step can cause problems when rendering the recipe."
                                                }
                                                if (stepTag.completesStep === sentence.id) {
                                                    return "Warning : There are steps depending on this step. Deleting this step can cause problems when rendering the recipe."
                                                }
                                            }
                                        })()}
                                        onDelete={
                                            () => {
                                                let tagIndex = recipe.tagIndex(sentence.id);
                                                recipe.save(recipe => {
                                                    recipe.original.stepSections[sectionIndex].steps[stepIndex].sentences.splice(sentenceIndex, 1);
                                                    if (tagIndex) {
                                                        recipe.tags.steps.splice(tagIndex, 1);
                                                    }
                                                });
                                            }
                                        } />
                                ))}
                            </React.Fragment>
                        ))}

                    </div>
                ))}

                <div className={classes.fabwrapper}>
                    <div className={classes.fab}>
                    <Tooltip title="Add" aria-label="Add">
                        <div>
                        <Fab color="secondary" onClick={this.handleOpenAddMenu}>
                                <AddIcon />
                            </Fab>
                            {this.props.saving && <CircularProgress size={68} className={classes.fabProgress} />}
                            </div>

                    </Tooltip>
                    </div>
                    <Tooltip title="Publish" aria-label="Publish">
                        <Fab color="primary" onClick={this.props.publish} className={classes.fab}>
                            <NoteAddIcon />
                        </Fab>
                    </Tooltip>
                </div>

                <Menu
                    id="add-menu"
                    anchorEl={this.state.addMenuAnchorElement}
                    keepMounted
                    open={Boolean(this.state.addMenuAnchorElement)}
                    onClose={this.handleCloseAddMenu}
                >
                    <MenuItem onClick={() => this.handleAddElement(AddElementType.Equipment)}>Equipment</MenuItem>
                    <MenuItem onClick={() => this.handleAddElement(AddElementType.Ingredient)}>Ingredient</MenuItem>
                    <MenuItem onClick={() => this.handleAddElement(AddElementType.Section)}>Section</MenuItem>
                    {recipe.original.stepSections.length > 0 && <MenuItem onClick={() => this.handleAddElement(AddElementType.Step)}>Step</MenuItem>}
                </Menu>
                <Dialog
                    open={this.state.dialogElementType === AddElementType.Equipment}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Equipment</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter a single equipment item.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="equipment"
                            label="Equipment"
                            type="text"
                            fullWidth
                            value={this.state.textBuffer}
                            onChange={(element) => {
                                this.setState({ textBuffer: element.target.value })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleSaveDialog(AddElementType.Equipment)} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.dialogElementType === AddElementType.Ingredient}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Ingredient</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter a single ingredient.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="ingredient"
                            label="Ingredient"
                            type="text"
                            fullWidth
                            value={this.state.textBuffer}
                            onChange={(element) => {
                                this.setState({ textBuffer: element.target.value })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleSaveDialog(AddElementType.Ingredient)} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.dialogElementType === AddElementType.Section}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Section</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter the section title.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="section-title"
                            label="Section Title"
                            type="text"
                            fullWidth
                            value={this.state.textBuffer}
                            onChange={(element) => {
                                this.setState({ textBuffer: element.target.value })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleSaveDialog(AddElementType.Section)} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.dialogElementType === AddElementType.Step}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Step</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Choose a section and add a single step.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="section"
                            label="Section"
                            fullWidth
                            select
                            SelectProps={{
                                native: true
                            }}
                            value={this.state.sectionId}
                            onChange={(element) => {
                                this.setState({ sectionId: element.target.value })
                            }}
                        >
                            {recipe.original.stepSections.map((section, index) => (
                                <option key={section.id} value={section.id}>
                                    {section.name ? section.name : "Untitled Section " + (index + 1)}
                                </option>
                            ))}

                        </TextField>
                        <TextField
                            margin="dense"
                            id="instruction"
                            label="Instruction"
                            type="text"
                            fullWidth
                            value={this.state.textBuffer}
                            onChange={(element) => {
                                this.setState({ textBuffer: element.target.value })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleSaveDialog(AddElementType.Step)} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    private handleExpandedChange = (key: string) => (event: any, expanded: boolean) => {
        this.setState({
            expandedKey: expanded ? key : "",
        });
    }

    private handleOpenAddMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            addMenuAnchorElement: event.currentTarget
        });
    }

    private handleAddElement(type: AddElementType) {
        this.setState({
            dialogElementType: type,
            addMenuAnchorElement: null
        });
    }

    private handleCloseAddMenu() {
        this.setState({
            addMenuAnchorElement: null
        });
    }

    private handleCloseDialog() {
        this.setState({
            dialogElementType: undefined,
            textBuffer: undefined,
            sectionId: undefined
        });
    }

    private handleSaveDialog(type: AddElementType) {
        let id = shortid.generate();
        let text = "";
        let sectionId = "";
        if (this.state.textBuffer !== undefined) {
            //@ts-ignore
            text = this.state.textBuffer;
        }
        if (this.state.sectionId !== undefined) {
            //@ts-ignore
            sectionId = this.state.sectionId;
        }

        const recipe = new MutableRecipe(this.props.recipe, this.props.save);

        recipe.save(
            (recipe) => {
                switch (type) {
                    case AddElementType.Equipment:
                        recipe.original.equipment.push({
                            id: id,
                            text: text
                        });
                        break;
                    case AddElementType.Ingredient:
                        recipe.original.ingredients.push({
                            id: id,
                            text: text
                        });
                        break;
                    case AddElementType.Section:
                        recipe.original.stepSections.push({
                            id: id,
                            name: text,
                            steps: []
                        });
                        break;
                    case AddElementType.Step:
                        let section = recipe.original.stepSections.find((section) => section.id === sectionId)
                        if (section === undefined) {
                            section = recipe.original.stepSections[0];
                        }
                        section.steps.push({
                            id: id,
                            sentences: [{ id: shortid.generate(), text: text }]
                        });
                        break;
                }
            });
        this.handleCloseDialog();
    }

}

export default withStyles(SentencePanelStyles)(RecipeEditForm);
