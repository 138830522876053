import { RecipeGenerationTypes } from 'recipe-generation';

export interface ImportsState {
    loading:boolean;
    loadingError?:string;
    imports:RecipeGenerationTypes.RecipeImportIndex[];
    loadingCurrentImport:boolean;
    currentImportError?:string
    currentImport?:RecipeGenerationTypes.RenderedRecipeImport;
    saving:boolean;
    saveStatus?:string;
    uploadingImages:ImageUploadPath[];
}

export interface ImageUploadPath {
    recipeId:string;
    type:string;
    itemId:string;
}

export const LOAD_IMPORTS_START = "LOAD_IMPORTS_START";
export const LOAD_IMPORTS_SUCCESS = "LOAD_IMPORTS_SUCCESS";
export const LOAD_IMPORTS_FAILED = "LOAD_IMPORTS_FAILED";

export const CREATE_IMPORT_START = "CREATE_IMPORT_START";
export const CREATE_IMPORT_SUCCESS = "CREATE_IMPORT_SUCCESS";
export const CREATE_IMPORT_FAILED = "CREATE_IMPORT_FAILED";

export const PUBLISH_IMPORT_START = "PUBLISH_IMPORT_START";

export const SAVE_IMPORT_START = "SAVE_IMPORT_START";
export const SAVE_IMPORT_SUCCESS = "SAVE_IMPORT_SUCCESS";

export const LOAD_IMPORT_START = "LOAD_IMPORT_START";
export const LOAD_IMPORT_SUCCESS = "LOAD_IMPORT_SUCCESS";
export const LOAD_IMPORT_FAILED = "LOAD_IMPORT_FAILED";
export const LOAD_IMPORT_CANCEL = "LOAD_IMPORT_CANCEL";

export const UPLOAD_IMAGE_START = "UPLOAD_IMAGE_START";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";

interface LoadImportsStartAction {
    type: typeof LOAD_IMPORTS_START;
}

interface LoadImportsSuccessAction {
    type: typeof LOAD_IMPORTS_SUCCESS;
    payload: RecipeGenerationTypes.RecipeImportIndex[];
}

interface LoadImportsFailedAction {
    type: typeof LOAD_IMPORTS_FAILED;
    error: string;
}

interface CreateImportStartAction {
    type: typeof CREATE_IMPORT_START;
}

interface CreateImportSuccessAction {
    type: typeof CREATE_IMPORT_SUCCESS;
    payload: RecipeGenerationTypes.RecipeImportIndex;
}

interface CreateImportFailedAction {
    type: typeof CREATE_IMPORT_FAILED;
    error: string;
}

interface PublishImportStartAction {
    type: typeof PUBLISH_IMPORT_START;
}

interface SaveImportStartAction {
    type: typeof SAVE_IMPORT_START;
    original?: RecipeGenerationTypes.RawRecipe;
    tags?: RecipeGenerationTypes.RecipeTags;
}

interface SaveImportSuccessAction {
    type: typeof SAVE_IMPORT_SUCCESS;
}


interface LoadImportStartAction {
    type: typeof LOAD_IMPORT_START;
}

interface LoadImportSuccessAction {
    type: typeof LOAD_IMPORT_SUCCESS;
    payload: RecipeGenerationTypes.RenderedRecipeImport;
}

interface LoadImportFailedAction {
    type: typeof LOAD_IMPORT_FAILED;
    error: string;
}

interface UploadImageStartAction {
    type: typeof UPLOAD_IMAGE_START;
    path:ImageUploadPath;
}

interface UploadImageSuccessAction {
    type: typeof UPLOAD_IMAGE_SUCCESS;
    path:ImageUploadPath;
    href: string;
}

interface UploadImageFailedAction {
    type: typeof UPLOAD_IMAGE_FAILED;
    path:ImageUploadPath;
    error: string;
}

export type ImportsActionTypes = LoadImportsStartAction | 
LoadImportsSuccessAction | 
LoadImportsFailedAction | 
CreateImportStartAction |
CreateImportSuccessAction |
CreateImportFailedAction |
PublishImportStartAction |
SaveImportStartAction |
SaveImportSuccessAction |
LoadImportStartAction |
LoadImportSuccessAction |
LoadImportFailedAction |
UploadImageStartAction |
UploadImageSuccessAction |
UploadImageFailedAction;