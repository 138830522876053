import * as React from 'react';
import { Component } from 'react';
import { RecipeGenerationTypes } from 'recipe-generation';
import SentencePanelItem from './SentencePanelItem';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, InputAdornment, TextField, DialogContentText } from '@material-ui/core';

interface TimerPanelItemProps {
    text: string;
    span?: RecipeGenerationTypes.Span;
    timeMin: number;
    timeMax?: number;
    onValuesChange:(timeMin:number, timeMax?:number)=>void;
    onDelete: () => void;
}

class TimerPanelItem extends Component<TimerPanelItemProps> {

    constructor(props: TimerPanelItemProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveDialog = this.handleSaveDialog.bind(this);
    }

    public state = {
        openDialogKey: "",
        timeMin:this.props.timeMin,
        timeMax:this.props.timeMax
    };

    componentWillReceiveProps(nextProps: TimerPanelItemProps) {
        this.setState({
            timeMin:nextProps.timeMin,
            timeMax:nextProps.timeMax
        });
    }

    render() {
        const { timeMin, timeMax, ...props } = this.props;

        var summary = "Min : " + timeMin.toString() + " seconds."
        if (timeMax) {
            summary += " Max : " + timeMax.toString() + " seconds."
        }

        return (
            <React.Fragment>
                <SentencePanelItem
                    type="Timer"
                    helpText='The span of text containing the timing info.'
                    summary={summary}
                    onEdit={() => this.handleOpenDialog("timer-info")}
                    {...props} />
                <Dialog open={this.state.openDialogKey === "timer-info"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Timer</DialogTitle>
                    <DialogContent>
                        <DialogContentText key="time-min-text">How long should the timer run before we first ask the user to verify?</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"time-min"}
                            key={"time-min-textfield"}
                            label={"Minimum time"}
                            type="number"
                            value={this.state.timeMin}
                            onChange={(element) => {
                                this.setState({ timeMin: element.target.value });
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
                            }}
                        />
                        <DialogContentText  key="time-max-text">How long should the timer run in total? This is used as a guidance for extending the timer.</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"time-max"}
                            key={"time-max-textfield"}
                            label={"Maximum time"}
                            type="number"
                            value={this.state.timeMax}
                            onChange={(element) => {
                                this.setState({ timeMax: element.target.value });
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSaveDialog} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.resetState();
    }

    private handleSaveDialog() {
        this.props.onValuesChange(this.state.timeMin, this.state.timeMax);
        this.resetState();
    }

    private resetState() {
        this.setState({ openDialogKey: "",
        timeMin:this.props.timeMin,
        timeMax:this.props.timeMax });
    }

}

export default TimerPanelItem;