import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { APIClient } from '../apiclient';
import { recipesReducer } from './recipes/reducers';
import { ActionType } from 'typesafe-actions';
import * as recipesAction from './recipes/actions';
import * as importsAction from './imports/actions';
import * as creationsAction from './imports/creation_actions';
import { importsReducer } from './imports/reducers';

const rootReducer = combineReducers({
    recipes : recipesReducer,
    imports: importsReducer,
    creations: importsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

const rootAction = {
    recipes: recipesAction,
    imports: importsAction,
    creations:creationsAction
  };

export type RootAction = ActionType<typeof rootAction>;


export default function configureStore(apiClient:APIClient) {

  const store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware.withExtraArgument(apiClient))
  );

  return store;
}
