import { LOAD_RECIPES_SUCCESS, LOAD_RECIPE_SUCCESS, LOAD_RECIPE_FAILED, LOAD_RECIPE_START } from "./types";
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { Action } from 'redux';
import { APIClient } from '../../apiclient';
import { RecipeRendering } from 'recipe-rendering';

export const loadRecipes = () : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    client.getRecipes().then( result => {
        dispatch(loadRecipesSuccess(result));
    }).catch( err => {
        dispatch(loadRecipesSuccess([]));
    });
}

export const loadRecipe = (id:string) : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({
        type: LOAD_RECIPE_START
      });
    client.getRecipe(id).then( result => {
        dispatch({
            type: LOAD_RECIPE_SUCCESS,
            payload: result
          });
    }).catch( err => {
        console.log(err);
        dispatch({
            type: LOAD_RECIPE_FAILED
          });
    });
}


export function loadRecipesSuccess(recipes: RecipeRendering.RenderedRecipe[]) {
    return {
      type: LOAD_RECIPES_SUCCESS,
      payload: recipes
    };
  }
  