import * as React from 'react';
import { Component } from 'react';
import { RecipeGenerationTypes } from 'recipe-generation';
import SentencePanelItem from './SentencePanelItem';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from '@material-ui/core';
import { DeletablePanelProps } from '../SentencePanel';
import NounPanelItem from './NounPanelItem';

interface QuantityPanelItemProps extends DeletablePanelProps {
    text:string;
    label:string;
    helpText:string;
    unitHelpText:string;
    quantity:RecipeGenerationTypes.Quantity;
    onQuantityChange:(quantity:RecipeGenerationTypes.Quantity)=>void;
}

class QuantityPanelItem extends Component<QuantityPanelItemProps> {

    constructor(props: QuantityPanelItemProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveDialog = this.handleSaveDialog.bind(this);
    }

    public state = {
        openDialogKey: "",
        quantity:JSON.parse(JSON.stringify(this.props.quantity)),
    };

    componentWillReceiveProps(nextProps: QuantityPanelItemProps) {
        this.setState({
            quantity:JSON.parse(JSON.stringify(nextProps.quantity)),
        });
    }

    render() {
        const { text, label, helpText, unitHelpText, quantity,onQuantityChange, ...props } = this.props;

        let stateCurrentQuantity = this.state.quantity;

        let summary = ""+quantity.quantity;

        if(quantity.divisor !== undefined && quantity.divisor !== 1){
            summary += "/"+quantity.divisor;
        }

        return (
            <React.Fragment>
                <SentencePanelItem
                    type={label}
                    text={text}
                    span={quantity.span}
                    summary={summary}
                    onEdit={() => this.handleOpenDialog("quantity-edit")}
                    {...props} />
                {quantity.unit && <NounPanelItem
                    text={text}
                    label="Unit"
                    noun={quantity.unit}
                    helpText={unitHelpText}
                    onNounChange={
                        (noun) => {
                            let newQuantity = JSON.parse(JSON.stringify(quantity));
                            newQuantity.unit = noun;
                            onQuantityChange(newQuantity);
                        }
                    }
                    onDelete={
                        () => {
                            let newQuantity = JSON.parse(JSON.stringify(quantity));
                            delete newQuantity.unit;
                            onQuantityChange(newQuantity);
                        }
                    }
                    {...props} />}
                <Dialog open={this.state.openDialogKey === "quantity-edit"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{label}</DialogTitle>
                    <DialogContent>
                        <DialogContentText key="help">Enter a quantity (numerator) and an optional denominator (defaults to 1).</DialogContentText>
                        <TextField
                            margin="dense"
                            id={"quantity"}
                            key={"quantity-textfield"}
                            value={stateCurrentQuantity.quantity}
                            type="number"
                            label="Quantity (Numerator)"
                            fullWidth
                            onChange={
                                (element) => {
                                    let newQuantity = JSON.parse(JSON.stringify(stateCurrentQuantity));
                                    newQuantity.quantity = parseInt(element.target.value);
                                    this.setState({quantity:newQuantity});
                            }}/>
                        <TextField
                            margin="dense"
                            id={"denominator"}
                            key={"denominator-textfield"}
                            value={stateCurrentQuantity.divisor !== undefined ? stateCurrentQuantity.divisor : 1}
                            type="number"
                            label="Denominator!"
                            fullWidth
                            onChange={
                                (element) => {
                                    let newQuantity = JSON.parse(JSON.stringify(stateCurrentQuantity));
                                    newQuantity.divisor = parseInt(element.target.value);
                                    this.setState({quantity:newQuantity});
                            }}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSaveDialog} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.resetState();
    }

    private handleSaveDialog() {
        this.props.onQuantityChange(this.state.quantity);
        this.resetState();
    }

    private resetState() {
        this.setState({ openDialogKey: "",
        quantity:this.props.quantity });
    }

}

export default QuantityPanelItem;