import * as firebase from "firebase/app";
import "firebase/auth";
import AuthProvider from '../apiclient';

export class FirebaseAuth implements AuthProvider {

    constructor(readonly firebaseAuth:firebase.auth.Auth){
    }

    public async getToken():Promise<string> {
        const user = this.firebaseAuth.currentUser;
        if(user == null){
            throw new Error("Unauthorized");
        }
        return await user.getIdToken();
    }

}