import * as React from 'react';
import { Component } from 'react';
import { ExpansiblePanelProps, TextEditablePanelProps, DeletablePanelProps, ImageUploadingPanelProps } from './SentencePanel';
import SentencePanel from './SentencePanel';
import SentencePanelItem from './items/SentencePanelItem';
import { renderSpan } from './items/SentencePanelItem';
import { MutableStepTag } from '../../store/MutableRecipe';
import TimerPanelItem from './items/TimerPanelItem';
import CategoryPanelItem from './items/CategoryPanelItem';
import { RecipeGenerationTypes } from 'recipe-generation';
//@ts-ignore
import * as levenshtein from 'js-levenshtein';
import IngredientPanelItem from './items/IngredientPanelItem';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from '@material-ui/core';
import QuantityPanelItem from './items/QuantityPanelItem';

export interface IngredientReference {
    id: string;
    singular: string;
    plural: string;
}

interface StepPanelProps extends ExpansiblePanelProps, TextEditablePanelProps, DeletablePanelProps, ImageUploadingPanelProps {
    sentences: RecipeGenerationTypes.RawRecipeSentence[];
    ingredientRefs: IngredientReference[];
    tag: MutableStepTag;
    onMoveStepBefore: (id: string) => void;
}

class StepPanel extends Component<StepPanelProps> {

    public static readonly SHORT = "Short Text";
    public static readonly TIMER = "Timer";
    public static readonly TIMER_NAME = "Timer Name";
    public static readonly TIMER_COMPLETION = "Timer Completion";
    public static readonly TIMER_TRIAL = "Timer Trial Instruction";
    public static readonly TIMER_BEGIN_TEXT = "Timer Begin Text";
    public static readonly INGREDIENT = "Ingredient";
    public static readonly QUANTITY = "Quantity";
    public static readonly TOPIC = "Topic";
    public static readonly LOOP = "Loop Question";
    public static readonly LOOP_FIRST_SEG = "First Loop Begining";
    public static readonly LOOP_END_SEG = "First Loop End";
    public static readonly LOOP_COMP_SEG = "First Loop Before Completion";

    public state = {
        addingDependency: false,
        openDialogKey: "",
        moveBeforeStep: undefined,
        timerNameOverride: undefined
    };

    constructor(props: StepPanelProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveMoveStep = this.handleSaveMoveStep.bind(this);
        this.handleSaveTimerName = this.handleSaveTimerName.bind(this);
    }


    componentWillReceiveProps(nextProps: StepPanelProps) {
        let timerNameOverride: string | undefined;
        if (nextProps.tag && nextProps.tag.timer && nextProps.tag.timer.nameOverride) {
            timerNameOverride = nextProps.tag.timer.nameOverride;
        }
        this.setState({
            timerNameOverride: timerNameOverride,
        });
    }

    public render() {
        const { text, tag, sentences, ingredientRefs, ...props } = this.props;

        let allowedTagTypes = [StepPanel.SHORT, StepPanel.TIMER, StepPanel.INGREDIENT, StepPanel.QUANTITY, StepPanel.TOPIC, StepPanel.LOOP];

        if (tag.timer) {
            allowedTagTypes.push(StepPanel.TIMER_NAME);
            allowedTagTypes.push(StepPanel.TIMER_COMPLETION);
            allowedTagTypes.push(StepPanel.TIMER_TRIAL);
            allowedTagTypes.push(StepPanel.TIMER_BEGIN_TEXT);
        }

        if (tag.loop) {
            allowedTagTypes.push(StepPanel.LOOP_FIRST_SEG);
            allowedTagTypes.push(StepPanel.LOOP_END_SEG);
            allowedTagTypes.push(StepPanel.LOOP_COMP_SEG);
        }

        let currentDirectlyFollowIndex = undefined;

        if (tag.completesStep) {
            let index = sentences.findIndex((sentence) => sentence.id === tag.completesStep);
            if (index !== -1) {
                currentDirectlyFollowIndex = index;
            }
        }

        var currentDependencyIndices: number[] = [];
        if (tag.dependencies) {
            tag.dependencies.forEach(dependency => {
                let index = sentences.findIndex((sentence) => sentence.id === dependency);
                if (index !== -1) {
                    currentDependencyIndices.push(index);
                }
            });
        }

        let ingredientRefsById = new Map<string, IngredientReference>();
        for (let ref of ingredientRefs) {
            ingredientRefsById.set(ref.id, ref);
        }

        const hasTags = tag.topicSpan !== undefined || tag.scalableIngredients !== undefined || tag.loop !== undefined || tag.timer !== undefined;

        const addingDependency = this.state.addingDependency;

        let loopType: RecipeGenerationTypes.LoopType = -1;

        if (tag.loop && tag.loop.loopType !== undefined) {
            if (tag.loop.loopType !== undefined) {
                if (tag.loop.loopType.toString() === 'PerSide' || tag.loop.loopType == 1) {
                    loopType = 0;
                }
            }
        }

        let topicSource = tag.topicSource;

        if (topicSource) {
            if (topicSource.toString() === "Next") {
                topicSource = 0;
            } else if (topicSource.toString() === "Previous") {
                topicSource = 1;
            }
        }

        let complements = tag.complements;

        if (complements) {
            if (complements.toString() === 'Next') {
                complements = 0;
            } else if (complements.toString() === 'Previous') {
                complements = 1;
            }
        }

        return (
            <SentencePanel
                title="Step"
                summary={text}
                text={text}
                tagTypes={allowedTagTypes}
                hasTags={hasTags}
                customButtons={addingDependency ? ["Move Step"] : ["Move Step", "Add Dependency"]}
                onCustomButton={
                    (button) => {
                        if (button === "Add Dependency") {
                            this.setState({ addingDependency: true })

                        } else if (button === "Move Step") {
                            this.handleOpenDialog("move-step");
                        }
                    }
                }
                onTag={
                    (type, span) => {
                        tag.save(tag => {
                            switch (type) {
                                case StepPanel.SHORT:
                                    tag.shortTextSpan = span;
                                    break;
                                case StepPanel.TOPIC:
                                    tag.topicSpan = span;
                                    delete tag.topicSource;
                                    break;
                                case StepPanel.INGREDIENT:
                                    if (tag.scalableIngredients === undefined) {
                                        tag.scalableIngredients = [];
                                    }
                                    let ingredientText = renderSpan(text, span);

                                    let sortedIngredients = ingredientRefs.slice().sort((a, b) => {
                                        let aScore = Math.min(levenshtein(ingredientText, a.singular), levenshtein(ingredientText, a.plural));
                                        let bScore = Math.min(levenshtein(ingredientText, b.singular), levenshtein(ingredientText, b.plural));
                                        if (aScore < bScore) {
                                            return -1;
                                        }
                                        if (aScore > bScore) {
                                            return 1;
                                        }
                                        return 0;
                                    });

                                    tag.scalableIngredients.push({
                                        id: sortedIngredients[0].id,
                                        portion: undefined,//TODO: attempt regex, remove span requirement
                                        span: span
                                    });
                                    break;
                                case StepPanel.QUANTITY:
                                    if (tag.quantities === undefined) {
                                        tag.quantities = [];
                                    }
                                    tag.quantities.push({
                                        span: span,
                                        quantity: 1,
                                        unit: {
                                            span: span,
                                            singular: renderSpan(text, span),
                                            plural: renderSpan(text, span)
                                        }
                                    });
                                    break;
                                case StepPanel.LOOP:
                                    if (tag.loop) {
                                        tag.loop.questionSpan = span;
                                    } else {
                                        tag.loop = {
                                            questionSpan: span
                                        }
                                    }
                                    break;
                                case StepPanel.LOOP_FIRST_SEG:
                                    if (tag.loop) {
                                        tag.loop.firstLoopInitialTextSpan = span;
                                    }
                                    break;
                                case StepPanel.LOOP_COMP_SEG:
                                    if (tag.loop) {
                                        tag.loop.firstLoopBeforeCompletionStepTextSpan = span;
                                    }
                                    break;
                                case StepPanel.LOOP_END_SEG:
                                    if (tag.loop) {
                                        tag.loop.firstLoopEndTextSpan = span;
                                    }
                                    break;
                                case StepPanel.TIMER:
                                    let textFragment = renderSpan(text, span);
                                    let timeInfo = extractTimeInfo(textFragment)
                                    if (tag.timer) {
                                        tag.timer.timeSpan = span;
                                        tag.timer.timeMin = timeInfo.timeMin;
                                        tag.timer.timeMax = timeInfo.timeMax;
                                    } else {
                                        tag.timer = {
                                            timeMin: timeInfo.timeMin,
                                            timeMax: timeInfo.timeMax,
                                            timeSpan: span
                                        };
                                    }
                                    break;
                                case StepPanel.TIMER_NAME:
                                    if (tag.timer) {
                                        tag.timer.nameSpan = span;
                                    }
                                    break;
                                case StepPanel.TIMER_BEGIN_TEXT:
                                    if (tag.timer) {
                                        tag.timer.beginInstructionSpan = span;
                                    }
                                    break;
                                case StepPanel.TIMER_COMPLETION:
                                    if (tag.timer) {
                                        tag.timer.completionCondition = span;
                                    }
                                    break;
                                case StepPanel.TIMER_TRIAL:
                                    if (tag.timer) {
                                        tag.timer.trialInstructionSpan = span;
                                    }
                                    break;
                            }
                        });
                    }
                }
                {...props} >
                {tag.shortTextSpan &&
                    <SentencePanelItem
                        key="Short Text"
                        type={StepPanel.SHORT}
                        text={text}
                        span={tag.shortTextSpan}
                        helpText='The short version of the text that will be read by default'
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    delete tag.shortTextSpan;
                                });
                            }
                        } />
                }
                {tag.topicSpan &&
                    <SentencePanelItem
                        key="topic"
                        type={StepPanel.TOPIC}
                        text={text}
                        span={tag.topicSpan}
                        helpText='The span of text containing the main topic of this step. If not set, the topic source will be used. Please set this if the name of the topic appears the step to aid with ML training.'
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    delete tag.topicSpan;
                                });
                            }
                        } />
                }
                {tag.scalableIngredients &&
                    tag.scalableIngredients.map((ingredient, index) => (
                        <IngredientPanelItem
                            key={"ingredient-" + index.toString()}
                            text={text}
                            ingredient={ingredient}
                            ingredientRefsById={ingredientRefsById}
                            onChange={
                                (id: string, portion?: RecipeGenerationTypes.PortionTag, speak?: boolean) => {
                                    tag.save((tag) => {
                                        if (tag.scalableIngredients) {
                                            tag.scalableIngredients[index].id = id;
                                            tag.scalableIngredients[index].portion = portion;
                                            tag.scalableIngredients[index].speakQuantity = speak;
                                        }
                                    });
                                }
                            }
                            onDelete={
                                () => {
                                    tag.save((tag) => {
                                        if (tag.scalableIngredients) {
                                            tag.scalableIngredients.splice(index, 1);
                                        }
                                    });
                                }
                            } />
                    ))
                }
                {tag.quantities &&
                    tag.quantities.map((quantity, index) => (
                        <QuantityPanelItem
                            key={"quantity-" + index.toString()}
                            text={text}
                            label="Quantity"
                            helpText="The help text"
                            unitHelpText="The unit help text"
                            quantity={quantity}
                            onQuantityChange={
                                (quantity) => {
                                    tag.save((tag) => {
                                        if (tag.quantities) {
                                            tag.quantities[index] = quantity;
                                        }
                                    });
                                }
                            }
                            onDelete={
                                () => {
                                    tag.save((tag) => {
                                        if (tag.quantities) {
                                            tag.quantities.splice(index, 1);
                                        }
                                    });
                                }
                            } />
                    ))
                }
                {tag.timer &&
                    <TimerPanelItem
                        key="timer"
                        text={text}
                        span={tag.timer.timeSpan}
                        timeMin={tag.timer.timeMin}
                        timeMax={tag.timer.timeMax}
                        onValuesChange={
                            (timeMin, timeMax) => {
                                tag.save((tag) => {
                                    if (tag.timer) {
                                        tag.timer.timeMin = timeMin;
                                        if (timeMax) {
                                            tag.timer.timeMax = timeMax;
                                        }
                                    }
                                });
                            }
                        }
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.timer) {
                                        delete tag.timer;
                                    }
                                });
                            }
                        } />
                }
                {tag.timer && tag.timer.nameSpan &&
                    <SentencePanelItem
                        key="timer-name"
                        type={StepPanel.TIMER_NAME}
                        text={tag.timer.nameOverride !== undefined ? tag.timer.nameOverride : text}
                        span={tag.timer.nameOverride !== undefined ? undefined : tag.timer.nameSpan}
                        onEdit={
                            () => {
                                this.setState({ openDialogKey: "edit-timer-name" });
                            }
                        }
                        helpText="The span of text containing a good name for the timer. If not set, it will default to the step's topic."
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.timer) {
                                        delete tag.timer.nameSpan;
                                        delete tag.timer.nameOverride;
                                    }
                                });
                            }
                        } />
                }
                {tag.timer && tag.timer.trialInstructionSpan &&
                    <SentencePanelItem
                        key="timer-trial"
                        type={StepPanel.TIMER_TRIAL}
                        text={text}
                        span={tag.timer.trialInstructionSpan}
                        helpText="The span of text containing the instruction to begin a timer that is being measured rather than counted down. This instruction will be removed from future instances of this step and replaced with a regular timer with a duration set by how long it took to complete the step."
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.timer) {
                                        delete tag.timer.trialInstructionSpan;
                                    }
                                });
                            }
                        } />
                }
                {tag.timer && tag.timer.beginInstructionSpan &&
                    <SentencePanelItem
                        key="timer-begin"
                        type={StepPanel.TIMER_BEGIN_TEXT}
                        text={text}
                        span={tag.timer.beginInstructionSpan}
                        helpText="The span of text containing text that will be read when the timer is set."
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.timer) {
                                        delete tag.timer.beginInstructionSpan;
                                    }
                                });
                            }
                        } />
                }
                {tag.timer && tag.timer.completionCondition &&
                    <SentencePanelItem
                        key="timer-completion"
                        type={StepPanel.TIMER_COMPLETION}
                        text={text}
                        span={tag.timer.completionCondition}
                        helpText='The span of text containing a condition to meet before going to the next step.'
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.timer) {
                                        delete tag.timer.completionCondition;
                                    }
                                });
                            }
                        } />
                }
                {tag.loop && tag.loop.questionSpan &&
                    <CategoryPanelItem
                        key="loop"
                        type={StepPanel.LOOP}
                        text={text}
                        span={tag.loop.questionSpan}
                        helpText='The span of text containing the looping information.'
                        categoryLabel='Loop type'
                        categoryHelpText='What kind of loop is represented?'
                        supportedCategories={[RecipeGenerationTypes.LoopType.PerSide]}
                        currentCategory={loopType}
                        labels={["Per Side"]}
                        defaultLabel={"Indefinite"}
                        onCategoryChange={
                            (category) => {
                                tag.save((tag) => {
                                    if (tag.loop) {
                                        console.log(category);
                                        if (category !== undefined) {
                                            console.log("Setting category");
                                            tag.loop.loopType = RecipeGenerationTypes.LoopType.PerSide;
                                        } else {
                                            console.log("Deleting category");
                                            delete tag.loop.loopType;
                                        }
                                    }
                                });
                            }
                        }
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    delete tag.loop;
                                });
                            }
                        } />
                }
                {tag.loop && tag.loop.firstLoopInitialTextSpan &&
                    <SentencePanelItem
                        key="timer-first-seg"
                        type={StepPanel.LOOP_FIRST_SEG}
                        text={text}
                        span={tag.loop.firstLoopInitialTextSpan}
                        helpText='Text read when the first loop begins.'
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.loop) {
                                        delete tag.loop.firstLoopInitialTextSpan;
                                    }
                                });
                            }
                        } />
                }
                {tag.loop && tag.loop.firstLoopBeforeCompletionStepTextSpan &&
                    <SentencePanelItem
                        key="timer-comp-seg"
                        type={StepPanel.LOOP_COMP_SEG}
                        text={text}
                        span={tag.loop.firstLoopBeforeCompletionStepTextSpan}
                        helpText='Text read when the first loop completes and transitions into its completion step.'
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.loop) {
                                        delete tag.loop.firstLoopBeforeCompletionStepTextSpan;
                                    }
                                });
                            }
                        } />
                }
                {tag.loop && tag.loop.firstLoopEndTextSpan &&
                    <SentencePanelItem
                        key="timer-end-seg"
                        type={StepPanel.LOOP_END_SEG}
                        text={text}
                        span={tag.loop.firstLoopEndTextSpan}
                        helpText='Text read when the first loop ends (returns from all completion steps).'
                        onDelete={
                            () => {
                                tag.save((tag) => {
                                    if (tag.loop) {
                                        delete tag.loop.firstLoopEndTextSpan;
                                    }
                                });
                            }
                        } />
                }
                <CategoryPanelItem
                    key="topic-source"
                    type="Topic Source"
                    text=""
                    helpText="Where this step's topic should be sourced from. Must be Self if this step has a topic source. Set to previous if the topic should be sourced from the previous step. Next otherwise."
                    categoryLabel="Topic Source"
                    categoryHelpText="Where this step's topic should be sourced from. Must be Self if this step has a topic source. Set to previous if the topic should be sourced from the previous step. Next otherwise."
                    supportedCategories={[RecipeGenerationTypes.Direction.Next, RecipeGenerationTypes.Direction.Previous]}
                    currentCategory={topicSource}
                    labels={["Next", "Previous"]}
                    defaultLabel={"Self"}
                    onCategoryChange={
                        (category) => {
                            tag.save((tag) => {
                                if (category !== undefined) {
                                    tag.topicSource = category;
                                } else {
                                    delete tag.topicSource;
                                }
                            });
                        }
                    } />
                <CategoryPanelItem
                    key="complements"
                    type="Complements"
                    text=""
                    helpText="A step that complements another is read with the previous or next step. For example, 'Bon appétit'. If this step is standalone, leave this to None. Set to Previous if the step complements (should be read with) the previous step. Next otherwise."
                    categoryLabel="Complements"
                    categoryHelpText="A step that complements another is read with the previous or next step. For example, 'Bon appétit'. If this step is standalone, leave this to None. Set to Previous if the step complements (should be read with) the previous step. Next otherwise."
                    supportedCategories={[RecipeGenerationTypes.Direction.Next, RecipeGenerationTypes.Direction.Previous]}
                    currentCategory={complements}
                    labels={["Next", "Previous"]}
                    defaultLabel={"None"}
                    onCategoryChange={
                        (category) => {
                            tag.save((tag) => {
                                if (category !== undefined) {
                                    tag.complements = category;
                                } else {
                                    delete tag.complements;
                                }
                            });
                        }
                    } />
                <CategoryPanelItem
                    key="directly-follows"
                    type="Directly Follows"
                    text=""
                    helpText="Identifier of a step that this step completes. Use this when a step should be kept separate from another step (so Complements doesn't suffice), but yet should follow directly after. For example, a completion step for a timer."
                    categoryLabel="Directly follows"
                    categoryHelpText="A step that complements another is read with the previous or next step. For example, 'Bon appétit'. If this step is standalone, leave this to None. Set to Previous if the step complements (should be read with) the previous step. Next otherwise."
                    supportedCategories={Array.from(Array(this.props.sentences.length).keys())}
                    currentCategory={currentDirectlyFollowIndex}
                    labels={this.props.sentences.map((sentence) => sentence.text)}
                    defaultLabel={"None"}
                    onCategoryChange={
                        (category) => {
                            tag.save((tag) => {
                                if (category !== undefined) {
                                    tag.completesStep = this.props.sentences[category].id;
                                } else {
                                    delete tag.completesStep;
                                }
                            });
                        }
                    } />
                {currentDependencyIndices.map((dependencyIndex, index) => (
                    <CategoryPanelItem
                        key={"dependency-" + index}
                        type="Dependency"
                        text=""
                        helpText="Identifier of steps that this step requires. This is used to calculate how much a user can move around while a timer is ongoing, for example."
                        categoryLabel="Dependency"
                        categoryHelpText="Identifier of steps that this step requires. This is used to calculate how much a user can move around while a timer is ongoing, for example."
                        supportedCategories={Array.from(Array(this.props.sentences.length).keys())}
                        currentCategory={dependencyIndex}
                        labels={this.props.sentences.map((sentence) => sentence.text)}
                        defaultLabel={"None"}
                        onCategoryChange={
                            (category) => {
                                tag.save((tag) => {
                                    if (category !== undefined) {
                                        if (tag.dependencies) {
                                            tag.dependencies[index] = this.props.sentences[category].id;
                                        }
                                    } else {
                                        if (tag.dependencies) {
                                            tag.dependencies.splice(index, 1);
                                        }
                                    }
                                });
                            }

                        } />
                ))}
                {addingDependency &&
                    <CategoryPanelItem
                        key="new-dependency"
                        type="Dependency"
                        text=""
                        helpText="Identifier of steps that this step requires. This is used to calculate how much a user can move around while a timer is ongoing, for example."
                        categoryLabel="Dependency"
                        categoryHelpText="Identifier of steps that this step requires. This is used to calculate how much a user can move around while a timer is ongoing, for example."
                        supportedCategories={Array.from(Array(this.props.sentences.length).keys())}
                        labels={this.props.sentences.map((sentence) => sentence.text)}
                        defaultLabel={"None"}
                        onCategoryChange={
                            (category) => {
                                console.log(category);
                                if (category !== undefined) {
                                    let id = this.props.sentences[category].id;
                                    console.log(id);
                                    tag.save((tag) => {
                                        if (tag.dependencies) {
                                            tag.dependencies.push(id);
                                        } else {
                                            tag.dependencies = [id];
                                        }
                                    });
                                }
                                this.setState({ addingDependency: false });
                            }
                        } />}
                {this.props.expanded && <Dialog
                    fullWidth
                    open={this.state.openDialogKey === "move-step"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Move Step</DialogTitle>
                    <DialogContent>
                        <DialogContentText key="help">Move the step before another step</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"category"}
                            select
                            key={"category-textfield"}
                            value={this.state.moveBeforeStep !== undefined ? this.state.moveBeforeStep : -1}
                            onChange={(element) => {
                                let value = parseInt(element.target.value);
                                if (value === -1) {
                                    this.setState({ moveBeforeStep: undefined });
                                } else {
                                    this.setState({ moveBeforeStep: value });
                                }
                            }}
                            SelectProps={{
                                native: true
                            }}>
                            <option key="null" value={-1}>None</option>
                            {this.props.sentences.map((value, index) => (
                                <option key={value.id} value={index}>
                                    {value.text}
                                </option>
                            ))}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.handleSaveMoveStep() }} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>}
                {this.props.expanded && tag.timer && tag.timer.nameSpan && <Dialog
                    fullWidth
                    open={this.state.openDialogKey === "edit-timer-name"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit Timer Name</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"timer-name"}
                            type="text"
                            key={"timer-name"}
                            value={this.state.timerNameOverride !== undefined ? this.state.timerNameOverride : renderSpan(text, tag.timer.nameSpan)}
                            onChange={(element) => {
                                this.setState({ timerNameOverride: element.target.value });
                            }}>
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.handleSaveTimerName() }} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>}
            </SentencePanel>
        )
    }

    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.setState({ openDialogKey: "", moveBeforeStep: undefined });
    }

    private handleSaveMoveStep() {
        let index = this.state.moveBeforeStep;
        if (index !== undefined) {
            let sentence = this.props.sentences[index];
            this.props.onMoveStepBefore(sentence.id);
        }
        this.setState({ openDialogKey: "", moveBeforeStep: undefined });
    }

    private handleSaveTimerName() {
        this.props.tag.save((tag) => {
            if (tag && tag.timer) {
                if (this.state.timerNameOverride) {
                    tag.timer.nameOverride = this.state.timerNameOverride;
                } else {
                    delete tag.timer.nameOverride;
                }
            }
        });
        this.setState({ openDialogKey: "", moveBeforeStep: undefined });
    }


}

interface TimeInfo {
    timeMin: number;
    timeMax?: number;
}

function extractTimeInfo(text: string): TimeInfo {

    let minutesRangeRe = /(\d+) to (\d+) minutes/;
    var result = minutesRangeRe.exec(text);
    if (result !== null) {
        return {
            timeMin: parseInt(result[1]) * 60,
            timeMax: parseInt(result[2]) * 60
        }
    }
    var minutesRe = /(\d+) minutes/;
    result = minutesRe.exec(text);
    if (result !== null) {
        return {
            timeMin: parseInt(result[1]) * 60
        }
    }
    var secondsRe = /(\d+) seconds/;
    result = secondsRe.exec(text);
    if (result !== null) {
        return {
            timeMin: parseInt(result[1])
        }
    }
    var secondsToMinuteRe = /(\d+) seconds to (\d+) minute/;
    result = secondsToMinuteRe.exec(text);
    if (result !== null) {
        return {
            timeMin: parseInt(result[1]),
            timeMax: parseInt(result[2]) * 60
        }
    }
    var secondsToMinutesRe = /(\d+) seconds to (\d+) minutes/;
    result = secondsToMinutesRe.exec(text);
    if (result !== null) {
        return {
            timeMin: parseInt(result[1]),
            timeMax: parseInt(result[2]) * 60
        }
    }

    return {
        timeMin: 30//Default
    }
}

export default StepPanel;