import * as React from 'react';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, withStyles, createStyles, Theme, WithStyles, IconButton, Button, Typography, Link, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { APIClient } from '../apiclient';
import EditIcon from '@material-ui/icons/Edit';
import { loadImports, createImport } from '../store/imports/actions';
import { RecipeGenerationTypes } from 'recipe-generation';
import { withRouter, RouteComponentProps } from 'react-router';

const tableStyles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

interface TableProps extends WithStyles<typeof tableStyles>, RouteComponentProps<any> {
  recipes: RecipeGenerationTypes.RecipeImportIndex[]
}

class ImportedRecipesTable extends React.Component<TableProps> {

  public render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.recipes.map(recipe => (
              <TableRow key={recipe.name}>
                <TableCell component="th" scope="row">
                  {recipe.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href={recipe.url}>{recipe.url}</Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  Incomplete, Not yet published
                      </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton onClick={() => this.handleOnClick(recipe.id)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  private handleOnClick(id: string) {
    this.props.history.push('/imports/' + id);
  }

}

const StyledImportedRecipesTable = withRouter(withStyles(tableStyles)(ImportedRecipesTable));

const styles = (theme: Theme) => createStyles({
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    margin: theme.spacing(1),
  }
});

interface RecipesProps extends WithStyles<typeof styles> {
  recipes: RecipeGenerationTypes.RecipeImportIndex[];
  reload: () => void;
  createImport: (url: string) => void;
}

class Imports extends React.Component<RecipesProps> {

  public state = {
    importDialogOpen: false,
    url: ""
  };

  public componentDidMount() {
    this.props.reload();
  }

  public render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.header}>
          <Typography variant="h4" gutterBottom>Imports</Typography>
          <Button variant="contained" color="primary" className={classes.button} onClick={this.handleImportDialogClickOpen}>Import New Recipe</Button>
        </div>
        <StyledImportedRecipesTable recipes={this.props.recipes} />
        <Dialog
          open={this.state.importDialogOpen}
          onClose={this.handleImportDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Import</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the URL of the recipe to import
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="url"
              label="URL"
              type="url"
              fullWidth
              onChange={(element) => { this.setState({url:element.target.value}) }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleImportDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleImportDialogSubmit} color="primary">
              Import
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  private handleImportDialogClickOpen = () => {
    this.setState({ importDialogOpen: true, url: "" });
  };

  private handleImportDialogSubmit = () => {
    this.props.createImport(this.state.url)
    this.setState({ importDialogOpen: false });
  };

  private handleImportDialogClose = () => {
    this.setState({ importDialogOpen: false });
  };
}
const mapStateToProps = (state: AppState) => ({
  recipes: state.imports.imports
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, APIClient, AnyAction>) => ({
  reload: () => dispatch(loadImports()),
  createImport: (url: string) => dispatch(createImport(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Imports));
