import * as React from 'react';
import * as PropTypes from 'prop-types';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { History } from "history";

const styles = createStyles({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

export interface IProps extends WithStyles<typeof styles> {}

/**
 * Issue from: https://github.com/mui-org/material-ui/issues/13218
 */

interface NavbarLinkButtonProps extends ButtonProps {
  to: History.LocationDescriptor;
  children: any;
}

const NavbarLinkButton: React.SFC<NavbarLinkButtonProps> = ({
  to,
  children,
  ...rest
}) => {
  const NavbarLink: React.SFC<any> = props => <Link to={to} {...props} />;

  return (
    <Button component={NavbarLink} {...rest}>
      {children}
    </Button>
  );
};

function ButtonAppBar(props: IProps) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Scrummy-Admin
          </Typography>
          <NavbarLinkButton color="inherit" to="/recipes">Recipes</NavbarLinkButton>
          <NavbarLinkButton color="inherit" to="/creations">Creations</NavbarLinkButton>
          <NavbarLinkButton color="inherit" to="/imports">Imports</NavbarLinkButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
} as any;

export default withStyles(styles)(ButtonAppBar);