import {
    ImportsState,
    LOAD_IMPORTS_SUCCESS,
    ImportsActionTypes,
    LOAD_IMPORTS_START,
    LOAD_IMPORTS_FAILED,
    LOAD_IMPORT_START,
    LOAD_IMPORT_SUCCESS,
    LOAD_IMPORT_FAILED,
    CREATE_IMPORT_START,
    CREATE_IMPORT_SUCCESS,
    SAVE_IMPORT_START,
    UPLOAD_IMAGE_START,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILED,
    SAVE_IMPORT_SUCCESS
} from "./types";
import { RecipeGenerationTypes } from "recipe-generation";

const initialState: ImportsState = {
    loading: false,
    loadingError: undefined,
    imports: [],
    saving:false,
    saveStatus: undefined,
    loadingCurrentImport: false,
    currentImportError: undefined,
    currentImport: undefined,
    uploadingImages: [],
};

export function importsReducer(
    state = initialState,
    action: ImportsActionTypes
): ImportsState {
    switch (action.type) {
        case LOAD_IMPORTS_START:
            return {
                loading: true,
                loadingError: undefined,
                imports: state.imports,
                saving:false,
                saveStatus: state.saveStatus,
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: state.currentImport,
                uploadingImages: state.uploadingImages,
            };
        case LOAD_IMPORTS_SUCCESS:
            return {
                loading: false,
                loadingError: undefined,
                imports: action.payload,
                saving:false,
                saveStatus: state.saveStatus,
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: state.currentImport,
                uploadingImages: state.uploadingImages,
            };
        case LOAD_IMPORTS_FAILED:
            return {
                loading: false,
                loadingError: action.error,
                imports: state.imports,
                saving:false,
                saveStatus: state.saveStatus,
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: state.currentImport,
                uploadingImages: state.uploadingImages,
            };
        case CREATE_IMPORT_START:
            //TODO: importing
            return state;
        case CREATE_IMPORT_SUCCESS:
            return {
                loading: state.loading,
                loadingError: state.loadingError,
                imports: state.imports.concat([action.payload]),
                saving:false,
                saveStatus: "Last saved : " + (new Date()).toLocaleString(),
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: state.currentImport,
                uploadingImages: state.uploadingImages,
            };
        case SAVE_IMPORT_START:
            let currentImport = state.currentImport;
            if (currentImport) {
                if (action.original) {
                    currentImport.original = action.original;
                }
                if (action.tags) {
                    currentImport.tags = action.tags;
                }
            }
            return {
                loading: state.loading,
                loadingError: state.loadingError,
                imports: state.imports,
                saving:true,
                saveStatus: state.saveStatus,
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: currentImport,
                uploadingImages: state.uploadingImages,
            }
        case SAVE_IMPORT_SUCCESS:
            return {
                loading: state.loading,
                loadingError: state.loadingError,
                imports: state.imports,
                saving:false,
                saveStatus: "Last saved : " + (new Date()).toLocaleString(),
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: state.currentImport,
                uploadingImages: state.uploadingImages,
            }
        case LOAD_IMPORT_START:
            return {
                loading: state.loading,
                loadingError: state.loadingError,
                imports: state.imports,
                saving:false,
                saveStatus: undefined,
                loadingCurrentImport: true,
                currentImportError: undefined,
                currentImport: undefined,
                uploadingImages: state.uploadingImages,
            };
        case LOAD_IMPORT_SUCCESS:
            return {
                loading: state.loading,
                loadingError: state.loadingError,
                imports: state.imports,
                saving:false,
                saveStatus: "Not modified",
                loadingCurrentImport: false,
                currentImportError: undefined,
                currentImport: action.payload,
                uploadingImages: state.uploadingImages,
            };
        case LOAD_IMPORT_FAILED:
            return {
                loading: false,
                loadingError: action.error,
                imports: state.imports,
                saving:false,
                saveStatus: state.saveStatus,
                loadingCurrentImport: false,
                currentImportError: action.error,
                currentImport: undefined,
                uploadingImages: state.uploadingImages,
            };
        case UPLOAD_IMAGE_START: {
            let uploadingImages = state.uploadingImages.slice();
            uploadingImages.push(action.path);
            return {
                loading: state.loading,
                loadingError: state.loadingError,
                imports: state.imports,
                saving:false,
                saveStatus: state.saveStatus,
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: state.currentImport,
                uploadingImages: uploadingImages,
            };
        }
        case UPLOAD_IMAGE_SUCCESS: {
            if (state.currentImport) {
                let newImport = JSON.parse(JSON.stringify(state.currentImport)) as RecipeGenerationTypes.RenderedRecipeImport;

                if(action.path.type === "sentence"){
                    for (let section of newImport.original.stepSections){
                        for (let step of section.steps){
                            for(let sentence of step.sentences){
                                if(sentence.id === action.path.itemId){
                                    sentence.image = action.href;
                                }
                            }
                        }
                    }
                } else if(action.path.type === "cover"){
                    newImport.original.image = action.href;
                }

                let uploadingImages = state.uploadingImages.slice();
                let index = uploadingImages.indexOf(action.path);
                if (index !== -1) {
                    uploadingImages.splice(index, 1);
                }
                return {
                    loading: state.loading,
                    loadingError: state.loadingError,
                    imports: state.imports,
                    saving:false,
                    saveStatus: state.saveStatus,
                    loadingCurrentImport: state.loadingCurrentImport,
                    currentImportError: state.currentImportError,
                    currentImport: newImport,
                    uploadingImages: uploadingImages,
                };
            }
            return state;
        }
        case UPLOAD_IMAGE_FAILED: {
            let uploadingImages = state.uploadingImages.slice();
            let index = uploadingImages.indexOf(action.path);
            if (index !== -1) {
                uploadingImages.splice(index, 1);
            }
            return {
                loading: state.loading,
                loadingError: state.loadingError,
                imports: state.imports,
                saving:false,
                saveStatus: state.saveStatus,
                loadingCurrentImport: state.loadingCurrentImport,
                currentImportError: state.currentImportError,
                currentImport: state.currentImport,
                uploadingImages: uploadingImages,
            };
        }
        default:
            return state;
    }
}
