import { LOAD_IMPORTS_SUCCESS, LOAD_IMPORTS_START, LOAD_IMPORTS_FAILED, CREATE_IMPORT_START, CREATE_IMPORT_FAILED, CREATE_IMPORT_SUCCESS, PUBLISH_IMPORT_START, SAVE_IMPORT_START } from "./types";
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { Action } from 'redux';
import { APIClient } from '../../apiclient';

export const loadCreations = () : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: LOAD_IMPORTS_START});
    client.getCreations().then( result => {
        dispatch({
            type: LOAD_IMPORTS_SUCCESS,
            payload: result
          });
    }).catch( err => {
        dispatch({
            type: LOAD_IMPORTS_FAILED,
            error: err
          });
    });
}

export const createRecipe = (title:string, yieldQuantity:string, description:string, equipment:string, ingredients:string, instructions:string) : ThunkAction<void, AppState, APIClient, Action<string>> => async (dispatch,state,client) => {
    dispatch({type: CREATE_IMPORT_START});
    client.createRecipe(title, yieldQuantity, description , equipment, ingredients,instructions).then( result => {
        dispatch({
            type: CREATE_IMPORT_SUCCESS,
            payload: result
          });
    }).catch( err => {
        dispatch({
            type: CREATE_IMPORT_FAILED,
            error: err
          });
    });
}