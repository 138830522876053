import * as React from 'react';
import { Component } from 'react';
import { RecipeGenerationTypes } from 'recipe-generation';
import SentencePanelItem from './SentencePanelItem';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { DeletablePanelProps } from '../SentencePanel';
import { IngredientReference } from '../StepPanel';

interface IngredientPanelItemProps extends DeletablePanelProps {
    text: string;
    ingredient: RecipeGenerationTypes.ScalableIngredients;
    ingredientRefsById: Map<string, IngredientReference>;
    onChange: (id: string, portion?: RecipeGenerationTypes.PortionTag, speakQuantity?: boolean) => void;
}

class IngredientPanelItem extends Component<IngredientPanelItemProps> {

    constructor(props: IngredientPanelItemProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveDialog = this.handleSaveDialog.bind(this);
    }

    public state = {
        openDialogKey: "",
        ingredientId: this.props.ingredient.id,
        portion: this.props.ingredient.portion,
        speakQuantity: this.props.ingredient.speakQuantity !== undefined ? this.props.ingredient.speakQuantity : false
    };

    componentWillReceiveProps(nextProps: IngredientPanelItemProps) {
        this.setState({
            ingredientId: this.props.ingredient.id,
            portion: nextProps.ingredient.portion,
            speakQuantity: nextProps.ingredient.speakQuantity !== undefined ? nextProps.ingredient.speakQuantity : false
        });
    }

    render() {
        const { text, ingredient, ingredientRefsById, ...props } = this.props;

        const ingredientRefs = Array.from(ingredientRefsById);

        return (
            <React.Fragment>
                <SentencePanelItem
                    type={"Ingredient"}
                    text={text}
                    span={ingredient.span}
                    summary={
                        (() => {
                            let ingredientRef = ingredientRefsById.get(ingredient.id);
                            if (ingredientRef) {
                                var rendering = "";
                                if(ingredient.speakQuantity){
                                    rendering += "Measured. ";
                                }

                                rendering += ingredientRef.singular + " / " + ingredientRef.plural;

                                if(ingredient.portion){
                                    rendering += ". Portion: "+ ingredient.portion.numerator  + " / " + ingredient.portion.denominator;
                                }

                                return rendering;
                            }
                            return "#Bad reference."
                        })()
                    }
                    helpText='Span of text containing an ingredient (and optionally also a portion or quantity)'
                    onEdit={() => this.handleOpenDialog("ingredient-edit")}
                    {...props} />
                <Dialog open={this.state.openDialogKey === "ingredient-edit"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Ingredient</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            select
                            id={"id-select"}
                            key={"id-select"}
                            value={this.state.ingredientId}
                            label="Ingredient"
                            fullWidth
                            onChange={
                                (element) => {
                                    this.setState({ ingredientId: element.target.value });
                                }}
                            SelectProps={{
                                native: true
                            }}>
                            {ingredientRefs.map(([id, ingredientRef]) => (
                                <option key={id} value={id}>
                                    {ingredientRef.plural}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            margin="dense"
                            id={"numerator"}
                            key={"numerator"}
                            value={this.state.portion != undefined ? this.state.portion.numerator : ""}
                            label="Numerator"
                            fullWidth
                            type="number"
                            onChange={
                                (element) => {
                                    let existingPortion = this.state.portion;
                                    let numerator = parseInt(element.target.value);
                                    if(existingPortion){
                                        let newPortion = JSON.parse(JSON.stringify(existingPortion));
                                        newPortion.numerator = numerator;
                                        this.setState({ portion: newPortion});
                                    } else {
                                        this.setState({ portion: {
                                            numerator:numerator,
                                            denominator:1,
                                            span:ingredient.span
                                        } });
                                    }
                                }}
                            />
                        <TextField
                            margin="dense"
                            id={"denominator"}
                            key={"denominator"}
                            value={this.state.portion != undefined ? this.state.portion.denominator : ""}
                            label="Denominator"
                            fullWidth
                            type="number"
                            onChange={
                                (element) => {
                                    let existingPortion = this.state.portion;
                                    let denominator = parseInt(element.target.value);
                                    if(existingPortion){
                                        let newPortion = JSON.parse(JSON.stringify(existingPortion));
                                        newPortion.denominator = denominator;
                                        this.setState({ portion: newPortion});
                                    } else {
                                        this.setState({ portion: {
                                            denominator:denominator,
                                            numerator:1,
                                            span:ingredient.span
                                        } });
                                    }
                                }}
                            />
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.speakQuantity}
                                        onChange={() => this.setState({ speakQuantity: !this.state.speakQuantity })}
                                        value="Measure quantity in this step"
                                        inputProps={{
                                            'aria-label': 'Measure quantity in this step',
                                        }}
                                    />
                                }
                                label="Measure quantity in this step"
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSaveDialog} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.resetState();
    }

    private handleSaveDialog() {
        let ingredientId = this.state.ingredientId;
        if(this.props.ingredientRefsById.get(ingredientId) === undefined){
            ingredientId = this.props.ingredientRefsById.values().next().value.id;
        }
        this.props.onChange(ingredientId, this.state.portion, this.state.speakQuantity);
        this.resetState();
    }

    private resetState() {
        this.setState({
            openDialogKey: "",
            ingredientId: this.props.ingredient.id,
            portion: this.props.ingredient.portion,
            speakQuantity: this.props.ingredient.speakQuantity !== undefined ? this.props.ingredient.speakQuantity : false
        });
    }

}

export default IngredientPanelItem;