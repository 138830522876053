import * as React from 'react';
import { Component } from 'react';
import { ExpansiblePanelProps, TextEditablePanelProps, DeletablePanelProps } from './SentencePanel';
import SentencePanel from './SentencePanel';
import { MutableIngredientTag } from '../../store/MutableRecipe';
import { renderSpan } from './items/SentencePanelItem';
import NounPanelItem from './items/NounPanelItem';
import QuantityPanelItem from './items/QuantityPanelItem';
import { Dialog, DialogContent, DialogContentText, TextField, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { RecipeGenerationTypes } from 'recipe-generation';

interface IngredientPanelProps extends ExpansiblePanelProps, TextEditablePanelProps, DeletablePanelProps {
    tag: MutableIngredientTag;
    allIngredients: RecipeGenerationTypes.RawRecipeIngredient[];
    onMoveIngredientBefore: (id: string) => void;
}

class IngredientPanel extends Component<IngredientPanelProps> {

    public state = {
        openDialogKey: "",
        moveBefore: undefined
    };

    constructor(props: IngredientPanelProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveMove = this.handleSaveMove.bind(this);
    }

    public render() {
        const { text, tag, ...props } = this.props;

        const tagTypes = ["Ingredient Name", "Quantity"];

        if (tag.quantity) {
            tagTypes.push("Unit");
        }

        return (
            <React.Fragment>
                <SentencePanel
                    title="Ingredient"
                    summary={text}
                    text={text}
                    tagTypes={tagTypes}
                    customButtons={["Move"]}
                    onCustomButton={
                        (button) => {
                            this.handleOpenDialog("move-ingredient");
                        }
                    }
                    onTag={
                        (type, span) => {

                            tag.save((tag) => {
                                if (type === "Ingredient Name") {
                                    tag.name = {
                                        span: span,
                                        singular: renderSpan(text, span),
                                        plural: renderSpan(text, span)
                                    };
                                } else if (tag && type === "Quantity") {
                                    tag.quantity = {
                                        span: span,
                                        quantity: parseInt(renderSpan(text, span))
                                    };
                                } else if (tag && tag.quantity && type === "Unit") {
                                    tag.quantity.unit = {
                                        span: span,
                                        singular: renderSpan(text, span),
                                        plural: renderSpan(text, span)
                                    }
                                }
                            });
                        }
                    }
                    {...props} >
                    {tag.name &&
                        <NounPanelItem
                            key="name"
                            text={text}
                            noun={tag.name}
                            label="Ingredient Name"
                            helpText="Span of text containing the name of the ingredient."
                            onNounChange={
                                (noun) => {
                                    tag.save(tag => {
                                        tag.name = noun;
                                    });
                                }
                            }
                            onDelete={
                                () => {
                                    tag.save(tag => {
                                        delete tag.name;
                                    });
                                }
                            }
                            {...props} />}
                    {tag.quantity &&
                        <QuantityPanelItem
                            key="quantity"
                            text={text}
                            quantity={tag.quantity}
                            label="Quantity"
                            helpText="Span of text containing the quantity (as integer) of the ingredient."
                            unitHelpText="Optional span of text containing the unit of the ingredient."
                            onQuantityChange={
                                (quantity) => {
                                    tag.save(tag => {
                                        tag.quantity = quantity;
                                    });
                                }
                            }
                            onDelete={
                                () => {
                                    tag.save(tag => {
                                        delete tag.quantity;
                                    });
                                }
                            }
                            {...props} />}
                </SentencePanel>
                {this.props.expanded && <Dialog
                    fullWidth
                    open={this.state.openDialogKey === "move-ingredient"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Move Ingredient</DialogTitle>
                    <DialogContent>
                        <DialogContentText key="help">Move the ingredient before another equipment</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id={"category"}
                            select
                            key={"category-textfield"}
                            value={this.state.moveBefore !== undefined ? this.state.moveBefore : -1}
                            onChange={(element) => {
                                let value = parseInt(element.target.value);
                                if (value === -1) {
                                    this.setState({ moveBefore: undefined });
                                } else {
                                    this.setState({ moveBefore: value });
                                }
                            }}
                            SelectProps={{
                                native: true
                            }}>
                            <option key="null" value={-1}>None</option>
                            {this.props.allIngredients.map((value, index) => (
                                <option key={value.id} value={index}>
                                    {value.text}
                                </option>
                            ))}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.handleSaveMove() }} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>}
            </React.Fragment>
        )
    }

    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.setState({ openDialogKey: "", moveBefore: undefined });
    }

    private handleSaveMove() {
        let index = this.state.moveBefore;
        if (index !== undefined) {
            let ingredient = this.props.allIngredients[index];
            this.props.onMoveIngredientBefore(ingredient.id);
        }
        this.setState({ openDialogKey: "", moveBefore: undefined });
    }

}

export default IngredientPanel;