import * as React from 'react';
import { match } from 'react-router-dom';
import { AppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { APIClient } from '../apiclient';
import { AnyAction } from 'redux';
import { loadRecipe } from '../store/recipes/actions';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view'
import { Recipe }  from 'recipe-core';

interface RecipeParams {
    id: string;
}  

interface RecipeProps {
    required: string;
    loading:boolean;
    recipe?:Recipe;
    reload:(id:string)=>void;
    match?: match<RecipeParams>;
}

class RecipeComponent extends React.Component<RecipeProps> {

    public componentDidMount() {
        if(this.props.match){
            this.props.reload(this.props.match.params.id);
        }
    }

    public render() {
        if(this.props.loading || this.props.match === undefined){
            return <h3>Loading...</h3>
        } else {
            if(this.props.match && this.props.recipe !== undefined){
                const {recipe} = this.props;
                return <ReactJson src={recipe} />
            } else {
                return <h3>Error</h3>
            }
        }
    }
}
  

const mapStateToProps = (state: AppState) => ({
    loading:state.recipes.loadingCurrentRecipe,
    recipe: state.recipes.currentRecipe
  });
  
  const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, APIClient, AnyAction>) => ({
    reload: (id:string) => dispatch(loadRecipe(id))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(RecipeComponent);