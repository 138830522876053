import * as React from 'react';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, withStyles, createStyles, Theme, WithStyles, IconButton, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState, RootAction } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { APIClient } from '../apiclient';
import EditIcon from '@material-ui/icons/Edit';
import { RecipeGenerationTypes } from 'recipe-generation';
import { withRouter, RouteComponentProps } from 'react-router';
import { loadCreations, createRecipe } from '../store/imports/creation_actions';

const tableStyles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

interface TableProps extends WithStyles<typeof tableStyles>, RouteComponentProps<any> {
  recipes: RecipeGenerationTypes.RecipeImportIndex[]
}

class ImportedRecipesTable extends React.Component<TableProps> {

  public render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.recipes.map(recipe => (
              <TableRow key={recipe.name}>
                <TableCell component="th" scope="row">
                  {recipe.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton onClick={() => this.handleOnClick(recipe.id)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  private handleOnClick(id: string) {
    this.props.history.push('/creations/' + id);
  }

}

const StyledImportedRecipesTable = withRouter(withStyles(tableStyles)(ImportedRecipesTable));

const styles = (theme: Theme) => createStyles({
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    margin: theme.spacing(1),
  }
});

interface RecipesProps extends WithStyles<typeof styles> {
  recipes: RecipeGenerationTypes.RecipeImportIndex[];
  reload: () => void;
  createRecipe: (title:string, yieldQuantity:string,description:string, equipment:string, ingredients:string, instructions:string) => void;
}

class Creations extends React.Component<RecipesProps> {

  public state = {
    createDialogOpen: false,
    title: "",
    yield: "",
    description:"",
    equipment:"",
    ingredients:"",
    instructions:""
  };

  constructor(props: RecipesProps) {
    super(props);
  }

  public componentDidMount() {
    this.props.reload();
  }

  public render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.header}>
          <Typography variant="h4" gutterBottom>Creations</Typography>
          <Button variant="contained" color="primary" className={classes.button} onClick={this.handleCreateDialogClickOpen}>Create New Recipe</Button>
        </div>
        <StyledImportedRecipesTable recipes={this.props.recipes} />
        <Dialog
          open={this.state.createDialogOpen}
          onClose={this.handleCreateDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Create New Recipe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter initial recipe text. This can all be edited later. 
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Title"
              type="text"
              fullWidth
              onChange={(element) => { this.state.title = element.target.value }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="yield"
              label="Yield"
              type="text"
              fullWidth
              helperText='Ex : This recipe is for 1 meatloaf.'
              onChange={(element) => { this.state.yield = element.target.value }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              onChange={(element) => { this.state.description = element.target.value }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Equipment"
              type="text"
              fullWidth
              multiline
              helperText='One line per equipment'
              onChange={(element) => { this.state.equipment = element.target.value }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Ingredients"
              type="text"
              fullWidth
              multiline
              helperText='One line per ingredient'
              onChange={(element) => { this.state.ingredients = element.target.value }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Instructions"
              type="text"
              fullWidth
              multiline
              helperText='One line per section. Optional section titles should begin with #.'
              onChange={(element) => { this.state.instructions = element.target.value }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCreateDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleCreationDialogSubmit} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  private handleCreateDialogClickOpen = () => {
    this.setState({ createDialogOpen: true});
  };

  private handleCreationDialogSubmit = () => {
    this.props.createRecipe(this.state.title,this.state.yield,this.state.description,this.state.equipment,this.state.ingredients,this.state.instructions);
    this.setState({ createDialogOpen: false });
  };

  private handleCreateDialogClose = () => {
    this.setState({ createDialogOpen: false });
  };
}


const mapStateToProps = (state: AppState) => ({
  recipes: state.creations.imports
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, APIClient, RootAction>) => ({
  reload: () => dispatch(loadCreations()),
  createRecipe: (title:string, yieldQuantity:string, description:string, equipment:string, ingredients:string, instructions:string) => dispatch(createRecipe(title,yieldQuantity,description,equipment,ingredients,instructions))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Creations));
