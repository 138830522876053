import { RecipeGenerationTypes } from 'recipe-generation';
import { RecipeRendering } from 'recipe-rendering';
import { ImageUploadPath } from '../store/imports/types';
import { Recipe } from 'recipe-core';

export default interface AuthProvider {
  getToken(): Promise<string>;
}

export interface Import {
  original: RecipeGenerationTypes.RawRecipe
  tags: RecipeGenerationTypes.RecipeTags
  rendering: RecipeRendering.RenderedRecipe
}

export class APIClient {

  constructor(
    readonly apiRoot: string,
    readonly authProvider: AuthProvider) {
  }

  public async getRecipe(id:string): Promise<Recipe> {
    const response = await fetch(this.apiRoot + "recipes/"+id+"?raw=true", {
      method: "GET",
      mode: "cors",
      headers: {
        "Authorization": await this.authorizationHeader()
      }
    });
    const result = await response.json();
    this.validateOk(response);
    return result as Recipe;
  }

  public async getRecipes(): Promise<RecipeRendering.RenderedRecipe[]> {
    const response = await fetch(this.apiRoot + "recipes", {
      method: "GET",
      mode: "cors",
      headers: {
        "Authorization": await this.authorizationHeader()
      }
    });
    const result = await response.json();
    this.validateOk(response);
    return result.recipes as RecipeRendering.RenderedRecipe[];
  }

  public async getImports(): Promise<string[]> {
    const response = await fetch(this.apiRoot + "imports", {
      method: "GET",
      mode: "cors",
      headers: {
        "Authorization": await this.authorizationHeader()
      }
    });
    const json = await response.json();
    this.validateOk(response);
    return json;
  }

  public async getCreations(): Promise<string[]> {
    const response = await fetch(this.apiRoot + "creations", {
      method: "GET",
      mode: "cors",
      headers: {
        "Authorization": await this.authorizationHeader()
      }
    });
    const json = await response.json();
    this.validateOk(response);
    return json;
  }

  public async createRecipe(title: string, yieldQuantity: string, description: string, equipment: string, ingredients: string, instructions: string): Promise<RecipeGenerationTypes.RecipeImportIndex> {
    const response = await fetch(this.apiRoot + "creations/new", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ title: title, yield: yieldQuantity, description: description, equipment: equipment, ingredients: ingredients, instructions: instructions }),
      headers: {
        "Authorization": await this.authorizationHeader(),
        "Content-Type": "application/json"
      }
    });

    const json = await response.json();
    this.validateOk(response);
    const result = json as RecipeGenerationTypes.RecipeImportIndex;
    return result;
  }

  public async createImport(url: string): Promise<RecipeGenerationTypes.RecipeImportIndex> {
    const response = await fetch(this.apiRoot + "imports/new", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ url: url }),
      headers: {
        "Authorization": await this.authorizationHeader(),
        "Content-Type": "application/json"
      }
    });

    const json = await response.json();
    this.validateOk(response);
    const result = json as RecipeGenerationTypes.RecipeImportIndex;
    return result;
  }

  public async uploadImage(path:ImageUploadPath, file:File) : Promise<string> {

    const formData = new FormData()
    formData.append("image", file);

    const response = await fetch(this.apiRoot + "imports/" + path.recipeId + "/newimage?type="+path.type+"&itemid="+path.itemId, {
      method: "POST",
      mode: "cors",
      body: formData,
      headers: {
        "Authorization": await this.authorizationHeader()
      }
    });

    const json = await response.json();
    this.validateOk(response);
    return json.href;
  }

  public async getImport(id: string): Promise<RecipeGenerationTypes.RecipeImport> {
    const response = await fetch(this.apiRoot + "imports/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Authorization": await this.authorizationHeader(),
        "Content-Type": "application/json"
      }
    });
    const json = await response.json();
    this.validateOk(response);
    const result = json as RecipeGenerationTypes.RecipeImport;
    return result;
  }

  public async save(id: string,
    original?: RecipeGenerationTypes.RawRecipe,
    tags?: RecipeGenerationTypes.RecipeTags): Promise<RecipeGenerationTypes.RecipeImport> {
    const response = await fetch(this.apiRoot + "imports/" + id, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        original: original,
        tags: tags
      }),
      headers: {
        "Authorization": await this.authorizationHeader(),
        "Content-Type": "application/json"
      }
    });
    const json = await response.json();
    this.validateOk(response);
    const result = json as RecipeGenerationTypes.RecipeImport;
    return result;
  }

  public async publishImport(id: string): Promise<void> {
    const response = await fetch(this.apiRoot + "recipes/import/" + id, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Authorization": await this.authorizationHeader()
      }
    });
    this.validateOk(response);
  }

  private async authorizationHeader(): Promise<string> {
    const token = await this.authProvider.getToken();
    return "Bearer " + token;
  }

  private validateOk(response: Response) {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
  }

}