import * as React from 'react';
import { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, createStyles, withStyles, WithStyles, Theme, Paper, MenuList, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, Button, Divider, ExpansionPanelActions, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField } from '@material-ui/core';
//@ts-ignore
import Popover from 'react-text-selection-popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SentencePanelStyles = (theme: Theme) => createStyles({
    input: {
        display: 'none',
    },
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

export interface Span {
    index: number;
    length: number;
}

export interface ExpansiblePanelProps {
    expanded: boolean;
    onExpandedChange: (event: any, expanded: boolean) => void;
}

export interface ImageUploadingPanelProps {
    imageHref?: string;
    uploadingImage?: boolean;
    onImageUpload?:(file:File)=>void;
}

export interface DeletablePanelProps {
    deletionWarning?:string;
    onDelete?:(() => void);
}

export interface TextEditablePanelProps {
    text: string;
    onTextChange: (text: string) => void;
}

interface SentencePanelProps extends WithStyles<typeof SentencePanelStyles>, TextEditablePanelProps, ExpansiblePanelProps, ImageUploadingPanelProps, DeletablePanelProps {

    title: string;
    summary: string;
    tagTypes: string[];
    hasTags?:boolean;
    customButtons?:string[];
    onCustomButton?:(button:string)=>void;
    onTag: (type: string, span: Span) => void;
}

class SentencePanel extends Component<SentencePanelProps> {

    public state = {
        openDialogKey: "",
        editText: this.props.text
    }

    private sentenceRef: React.RefObject<HTMLSpanElement>

    constructor(props: SentencePanelProps) {
        super(props);
        this.sentenceRef = React.createRef();
        this.handleTextSelection = this.handleTextSelection.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveEditText = this.handleSaveEditText.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
    }

    componentWillReceiveProps(nextProps: SentencePanelProps) {
        this.setState({
            openDialogKey: "",
            editText: nextProps.text
        });
    }

    public render() {
        const { classes } = this.props;
        const hasTags = this.props.hasTags;
        const uploadingImage = this.props.uploadingImage !== undefined ? this.props.uploadingImage : false;
        const canDelete = this.props.onDelete !== undefined;
        const hasValidChildren = this.props.children && React.Children.toArray(this.props.children).filter((child) => React.isValidElement(child)).length > 0;
        const displayTextChangeWarning = hasTags !== undefined ? hasTags : hasValidChildren;
        return (
            <React.Fragment>
                <ExpansionPanel TransitionProps={{ unmountOnExit: true }} expanded={this.props.expanded} onChange={this.props.onExpandedChange} >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>{this.props.title}</Typography>
                        <Typography className={classes.secondaryHeading}>{truncate(this.props.summary, 50)}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails ref={this.sentenceRef} >
                        <Typography>
                            {this.props.text}
                        </Typography>
                        <Popover selectionRef={this.sentenceRef}>
                            <Paper>
                                <MenuList>
                                    {this.props.tagTypes.map(tagType => (
                                        <MenuItem key={tagType} onClick={() => this.handleTextSelection(tagType)}>{tagType}</MenuItem>
                                    ))}
                                </MenuList>
                            </Paper>
                        </Popover>
                    </ExpansionPanelDetails>
                    {hasValidChildren &&
                        <ExpansionPanelDetails>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Text</TableCell>
                                        <TableCell>Data</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.children}
                                </TableBody>
                            </Table>
                        </ExpansionPanelDetails>
                    }
                    <Divider />
                    <ExpansionPanelActions>
                        {this.props.customButtons && this.props.onCustomButton && this.props.customButtons.map(button => (
                            <Button key={"button-"+button} size="small" color="primary" onClick={() => this.props.onCustomButton ? this.props.onCustomButton(button) : console.log(button)}>{button}</Button>
                        ))}
                        <Button size="small" color="primary" onClick={() => this.handleOpenDialog("edit-text")}>Edit Text</Button>
                        {this.props.onImageUpload && this.props.imageHref && <Button size="small" href={this.props.imageHref} target="_blank">View Image</Button>}
                        {this.props.onImageUpload &&
                            <div className={classes.wrapper}>
                                <input
                                    disabled={uploadingImage}
                                    accept="image/*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={ 
                                        (event) => {
                                            let files = event.target.files;
                                            if(files === null){
                                                return;
                                            }
                                            let file = files[0];
                                            if (file !== undefined && this.props.onImageUpload !== undefined) {
                                                this.props.onImageUpload(file);
                                            }
                                        }
                                    }
                                />
                                <label htmlFor="contained-button-file">
                                    <Button disabled={uploadingImage} size="small" component="span">
                                        Upload Image
                                            </Button>
                                    {uploadingImage && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </label>
                            </div>
                        }
                        {canDelete && <Button size="small" onClick={() => this.onDelete()} color="secondary">Delete {this.props.title}</Button>}
                    </ExpansionPanelActions>
                </ExpansionPanel>
                <Dialog 
                    fullWidth
                    open={this.state.openDialogKey === "edit-text"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit Text</DialogTitle>
                    <DialogContent>
                        {displayTextChangeWarning && <DialogContentText>
                            Warning : This will discard all tags associated with the previous text.
                        </DialogContentText>}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="text"
                            label="Text"
                            type="text"
                            fullWidth
                            multiline
                            value={this.state.editText}
                            onChange={(element) => {
                                this.setState({ editText: element.target.value });
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.handleSaveEditText() }} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.props.deletionWarning && 
                    <Dialog 
                    open={this.state.openDialogKey === "warn-delete"}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                           {this.props.deletionWarning}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.onDeleteConfirmed() }} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                    </Dialog>
                }
                
            </React.Fragment>
        );
    }

    private handleOpenDialog(key: string) {
        this.setState({ openDialogKey: key });
    }

    private handleCloseDialog() {
        this.resetState();
    }

    private handleSaveEditText() {
        this.props.onTextChange(this.state.editText);
        this.resetState();
    }

    private onDelete() {
        if(this.props.deletionWarning){
            this.handleOpenDialog("warn-delete");
        } else {
            this.onDeleteConfirmed();
        }
    }

    private onDeleteConfirmed() {
        if(this.props.onDelete){
            this.props.onDelete();
        }
    }

    private resetState() {
        this.setState({
            openDialogKey: "",
            editText: this.props.text
        });
    }

    private handleTextSelection(type: string) {
        //@ts-ignore
        const result = window.getSelection().getRangeAt(0);
        this.props.onTag(
            type,
            {
                index: result.startOffset,
                length: result.endOffset - result.startOffset
            }
        )
        //@ts-ignore
        window.getSelection().removeAllRanges();
    }
}

export default withStyles(SentencePanelStyles)(SentencePanel);

function truncate(input: string, length: number) {
    if (input.length > length) {
        return input.substring(0, length) + '...';
    }
    return input;
};