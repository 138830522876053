import {
  RecipesState,
  LOAD_RECIPES_SUCCESS,
  RecipesActionTypes,
  LOAD_RECIPE_SUCCESS,
  LOAD_RECIPE_FAILED,
  LOAD_RECIPE_START
} from "./types";

const initialState: RecipesState = {
  loading: false,
  recipes: [],
  loadingCurrentRecipe: false,
  currentRecipe: undefined
};

export function recipesReducer(
  state = initialState,
  action: RecipesActionTypes
): RecipesState {
  switch (action.type) {
    case LOAD_RECIPES_SUCCESS:
      return {
        loading: false,
        recipes: action.payload,
        loadingCurrentRecipe: false,
        currentRecipe: undefined
      };
    case LOAD_RECIPE_START:
      return {
        loading: state.loading,
        recipes: state.recipes,
        loadingCurrentRecipe: true,
        currentRecipe: undefined
      };
    case LOAD_RECIPE_SUCCESS:
      return {
        loading: state.loading,
        recipes: state.recipes,
        loadingCurrentRecipe: false,
        currentRecipe: action.payload
      };
    case LOAD_RECIPE_FAILED:
      return {
        loading: state.loading,
        recipes: state.recipes,
        loadingCurrentRecipe: false,
        currentRecipe: state.currentRecipe
      };
    default:
      return state;
  }
}
